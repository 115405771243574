import React, { Component } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Input, Icon } from "semantic-ui-react";

import "./Location.scss";

class OuterPlaceAutoSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      address: "",
    };
  }

  handleChange = (address) => {
    this.setState(() => {
      return { address };
    });
  };

  handleSelect = (address) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAnOMBzboGt-zL69J7ae_aC_yjWchdAn8M`
    )
      .then((result) => result.json())
      .then((response) => {
        this.props.onChange({
          googlePlaceId: response.results[0].place_id,
          address: response.results[0].address_components,
          addressForm: response.results[0].formatted_address,
          googleResponse: response.results[0].geometry,
          location: [
            response.results[0] && response.results[0].geometry.location.lat,
            response.results[0] && response.results[0].geometry.location.lng,
          ],
        });
        this.setState({ address });
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { classes, label, ...props } = this.props;

    return (
      <PlacesAutocomplete
        name={"searchbox"}
        id={"searchbox"}
        {...props}
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
        searchOptions={{
          types: ["address"],
          componentRestrictions: { country: "us" },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="location-field">
            <Input
              {...getInputProps({
                placeholder: "Search Places ...",
              })}
              icon={
                <Icon
                  style={{ marginTop: "5px" }}
                  onClick={() => this.props.checkValue()}
                  name="search"
                  inverted
                  circular
                  link
                />
              }
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default OuterPlaceAutoSearch;
