import React from "react";
import { Button, Modal, Header } from "semantic-ui-react";
import moment from "moment";
import { PERSON_URL } from "../../utils";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import * as Yup from "yup";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { updatePersonGql } from "../../store/person/person";
import { Formik, Form, Field, ErrorMessage } from "formik";
import mixpanel from "mixpanel-browser";

const personMainClient = getClient(PERSON_URL);

function AddRentalModal(props) {
  const [open, setOpen] = React.useState(false);
  const [endDate, setDate] = React.useState(false);
  const { location, fullAddress } = props;

  function extractFromObject(object, key) {
    return (
      object
        .filter((component) => component.types.indexOf(key) === 0)
        .map((item) => item.long_name)
        .pop() || null
    );
  }
  function extractFromObjectShort(object, key) {
    return (
      object
        .filter((component) => component.types.indexOf(key) === 0)
        .map((item) => item.short_name)
        .pop() || null
    );
  }
  const Attach = (a) => {
    console.log("Address Components" + JSON.stringify(props.location.address));

    const street =
      extractFromObject(
        fullAddress
          ? props.location.address
          : a.streetAddress.address.results[0].address_components,
        "street_number"
      ) +
      " " +
      extractFromObject(
        fullAddress
          ? props.location.address
          : a.streetAddress.address.results[0].address_components,
        "route"
      );
    const city = extractFromObject(
      fullAddress
        ? props.location.address
        : a.streetAddress.address.results[0].address_components,
      "locality"
    );
    const state = extractFromObjectShort(
      fullAddress
        ? props.location.address
        : a.streetAddress.address.results[0].address_components,
      "administrative_area_level_1"
    );
    const post = extractFromObject(
      fullAddress
        ? props.location.address
        : a.streetAddress.address.results[0].address_components,
      "postal_code"
    );
    const sendObject = {
      location: fullAddress
        ? props.location.location
        : a.streetAddress.address.results[0].geometry.location,
      date: moment(endDate).format("ll"),
      bedrooms: a.bedrooms,
      bathrooms: a.bathrooms,
      feet: a.feet,
      unit: a.unit,
    };
    mixpanel.track("Renter Explore Action", {
      sub: "Attach Non REM Property Submit ",
    });
    try {
      personMainClient
        .mutate({
          mutation: updatePersonGql,
          variables: {
            input: {
              address: {
                street: street,
                city: city,
                state: state,
                postcode: post,
                action: "create",
                type: "mailing",
                googlePlacesInfo: sendObject,
                current: true,
              },
            },
          },
        })
        .then((res) => {
          setOpen(false);
          props.success();
        })
        .catch((error) => {
          setOpen(false);
          props.fail();
          console.log(error);
        });
    } catch (e) {
      setOpen(false);
      props.fail();
      console.log(e);
    }
  };

  return (
    <Modal
      closeIcon
      className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Button fluid style={{ marginRight: "0px" }}>
          Not Seeing Your Property?
        </Button>
      }
    >
      <Modal.Header>{location.addressForm}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          It does not seem like this property is a REM location. Don't
          worry, you can still add this location and benefit from most of what
          REM has to offer.
        </Modal.Description>
        <Header>Help us out by adding some information.</Header>
        <Formik
          initialValues={{
            bedrooms: "",
            bathrooms: "",
            feet: "",
            unit: "",
          }}
          onSubmit={(values, actions) => {
            Attach(values);
            actions.resetForm();
          }}
          validationSchema={Yup.object().shape({
            bedrooms: Yup.number().required("Required"),
            bathrooms: Yup.number().required("Required"),
            feet: Yup.number().required("Required"),
          })}
          render={({
            values,
            setFieldValue,
            errors,
            touched,
            handleReset,
            handleSubmit,
          }) => (
            <Form textAlign="center" class="input-with-button" id="contactForm">
              <div class="row">
                <div class="col-6 col-lg-6">
                  <div className="form-group add-non-network">
                    <label for="company">Lease End</label>
                    <Datetime
                      onChange={setDate}
                      timeFormat={false}
                      viewMode="days"
                      type="text"
                      input={false}
                      minDate={moment().toDate()}
                      label="End"
                      name="end"
                      id="end"
                      placeholder="End"
                    />
                  </div>
                </div>

                <div class="col-6 col-lg-6">
                  <br></br>

                  <div className="form-group">
                    <label for="company">Unit</label>
                    <Field
                      class={`form-control ${
                        errors.unit && touched.unit && "is-invalid"
                      }`}
                      id="unit"
                      type="unit"
                      name="unit"
                      placeholder="Unit"
                    />
                    <ErrorMessage
                      className="invalid-feedback"
                      name="unit"
                      component="div"
                    />
                  </div>
                  <div className="form-group">
                    <label for="company">Bedrooms</label>
                    <Field
                      class={`form-control ${
                        errors.bedrooms && touched.bedrooms && "is-invalid"
                      }`}
                      id="bedrooms"
                      type="bedrooms"
                      name="bedrooms"
                      placeholder="Bedrooms"
                    />
                    <ErrorMessage
                      className="invalid-feedback"
                      name="bedrooms"
                      component="div"
                    />
                  </div>

                  <div className="form-group">
                    <label for="company">Bathrooms</label>
                    <Field
                      class={`form-control ${
                        errors.bathrooms && touched.bathrooms && "is-invalid"
                      }`}
                      id="bathrooms"
                      type="bathrooms"
                      name="bathrooms"
                      placeholder="Bathrooms"
                    />
                    <ErrorMessage
                      className="invalid-feedback"
                      name="bathrooms"
                      component="div"
                    />
                  </div>

                  <div className="form-group">
                    <label for="company">Square Feet</label>
                    <Field
                      class={`form-control ${
                        errors.feet && touched.feet && "is-invalid"
                      }`}
                      id="feet"
                      type="feet"
                      name="feet"
                      placeholder="Sq Feet"
                    />
                    <ErrorMessage
                      className="invalid-feedback"
                      name="feet"
                      component="div"
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-dark"
                    onClick={handleSubmit}
                  >
                    Attach Property
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      </Modal.Content>
      {/* <Modal.Actions>
        <Button
          content="Attach"
          labelPosition='right'
          icon='checkmark'
          onClick={() => Attach()}
          positive
        />
      </Modal.Actions> */}
    </Modal>
  );
}

export default AddRentalModal;
