import React from 'react'
import { Button, Grid, Header, Image, Modal, Checkbox, Input } from 'semantic-ui-react'
import { CATEGORIES_VALUES, MAINTENANCE_URL, LEASES_URL } from "../../utils/constants";
import getAuthToken from "../../store/auth/authUtility";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";

function MoveInChecklist(props) {
    const [open, setOpen] = React.useState(false)
    const [Checklist, setChecklist] = React.useState([
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },
        { value: true, explain: '' },

    ])
    const SubmitForm = async () => {
        const formData = new FormData();
        formData.append(
            "query",
            `mutation {
                createChecklist(input: {
                  checklist: {
                    moveInChecklist: "${JSON.stringify(Checklist).replaceAll(/"/g, "'")}", 
                    leaseId:"${props.lease.node.id}"
                  }
                }){
                  response
                }}`
        );
        if (formData) {
            await fetch(LEASES_URL, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    Authorization: getAuthToken(),
                    // contentType: "application/x-www-form-urlencoded"
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *client
                body: formData, // body data type must match "Content-Type" header
            })
                .then((result) => {
                    toastSuccessMsg(
                        "Your Checklist was successfully submitted."
                    )
                    setOpen(false)
                })
                .catch((error) => {
                    toastFailMsg("An error occurred. Please try again later.");
                });
        }


    }

    const updateHandler = (id, value) => {
        const newa = Checklist.map((item, index) => {
            if (index == id) {
                item.value = value
                return item
            }
            else return item
        })
        setChecklist(newa)
    }
    const textHandler = (a, e) => {
        const newaa = Checklist.map((item, index) => {
            if (index == a) {
                item.explain = e
                return item
            }
            else return item
        })
        setChecklist(newaa)
    }

    return (
        <Modal
            className="semanticModal"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            size="large"
            open={open}
            trigger={<Button
                basic
                compact
                content='Checklist'
                icon='clipboard'
                style={{ background: '#cce2ff' }}
            />}
        >
            <Modal.Header>Move In Checklist</Modal.Header>
            <Modal.Content className="checklist-modal">
                <Modal.Description>
                    <Grid>
                        <br></br>
                        <Header>Kitchen</Header>
                        <Grid.Row>
                            <Grid.Column width="4">Floor</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[0].value === true}
                                onClick={(e) => updateHandler(0, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[0].value === false}
                                onClick={(e) => updateHandler(0, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(0, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Walls</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[1].value === true}
                                onClick={(e) => updateHandler(1, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[1].value === false}
                                onClick={(e) => updateHandler(1, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(1, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Ceiling</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[2].value === true}
                                onClick={(e) => updateHandler(2, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[2].value === false}
                                onClick={(e) => updateHandler(2, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(2, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Refrigerator</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[3].value === true}
                                onClick={(e) => updateHandler(3, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[3].value === false}
                                onClick={(e) => updateHandler(3, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(3, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Range</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[4].value === true}
                                onClick={(e) => updateHandler(4, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[4].value === false}
                                onClick={(e) => updateHandler(4, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(4, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Sinks</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[5].value === true}
                                onClick={(e) => updateHandler(5, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[5].value === false}
                                onClick={(e) => updateHandler(5, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(5, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Windows</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[6].value === true}
                                onClick={(e) => updateHandler(6, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[6].value === false}
                                onClick={(e) => updateHandler(6, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(6, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Lighting</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[7].value === true}
                                onClick={(e) => updateHandler(7, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[7].value === false}
                                onClick={(e) => updateHandler(7, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(7, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row><Grid.Row>
                            <Grid.Column width="4">Outlets</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[8].value === true}
                                onClick={(e) => updateHandler(8, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[8].value === false}
                                onClick={(e) => updateHandler(8, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(8, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Cabinets</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[9].value === true}
                                onClick={(e) => updateHandler(9, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[9].value === false}
                                onClick={(e) => updateHandler(9, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(9, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Other</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[10].value === true}
                                onClick={(e) => updateHandler(10, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[10].value === false}
                                onClick={(e) => updateHandler(10, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(10, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>

                        <Header>Living Room</Header>
                        <Grid.Row>
                            <Grid.Column width="4">Floor</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[11].value === true}
                                onClick={(e) => updateHandler(11, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[11].value === false}
                                onClick={(e) => updateHandler(11, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(11, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Walls</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[12].value === true}
                                onClick={(e) => updateHandler(12, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[12].value === false}
                                onClick={(e) => updateHandler(12, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(12, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Ceiling</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[13].value === true}
                                onClick={(e) => updateHandler(13, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[13].value === false}
                                onClick={(e) => updateHandler(13, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(13, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Windows</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[14].value === true}
                                onClick={(e) => updateHandler(14, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[14].value === false}
                                onClick={(e) => updateHandler(14, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(14, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Lighting</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[15].value === true}
                                onClick={(e) => updateHandler(15, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[15].value === false}
                                onClick={(e) => updateHandler(15, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(15, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Outlets</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[16].value === true}
                                onClick={(e) => updateHandler(16, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[16].value === false}
                                onClick={(e) => updateHandler(16, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(16, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Other</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[17].value === true}
                                onClick={(e) => updateHandler(17, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[17].value === false}
                                onClick={(e) => updateHandler(17, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(17, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>

                       <Header>Bedrooms</Header>
                        <Grid.Row>
                            <Grid.Column width="4">Floor</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[18].value === true}
                                onClick={(e) => updateHandler(18, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[18].value === false}
                                onClick={(e) => updateHandler(18, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(18, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Walls</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[19].value === true}
                                onClick={(e) => updateHandler(19, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[19].value === false}
                                onClick={(e) => updateHandler(19, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(19, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Ceiling</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[20].value === true}
                                onClick={(e) => updateHandler(20, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[20].value === false}
                                onClick={(e) => updateHandler(20, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(20, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Windows</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[21].value === true}
                                onClick={(e) => updateHandler(21, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[21].value === false}
                                onClick={(e) => updateHandler(21, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(21, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Lighting</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[22].value === true}
                                onClick={(e) => updateHandler(22, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[22].value === false}
                                onClick={(e) => updateHandler(22, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(22, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Outlets</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[23].value === true}
                                onClick={(e) => updateHandler(23, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[23].value === false}
                                onClick={(e) => updateHandler(23, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(23, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Doors</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[24].value === true}
                                onClick={(e) => updateHandler(24, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[24].value === false}
                                onClick={(e) => updateHandler(24, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(24, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Closets</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[25].value === true}
                                onClick={(e) => updateHandler(25, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[25].value === false}
                                onClick={(e) => updateHandler(25, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(25, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Other</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[26].value === true}
                                onClick={(e) => updateHandler(26, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[26].value === false}
                                onClick={(e) => updateHandler(26, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(26, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>

                        <Header>Bathrooms</Header>
                        <Grid.Row>
                            <Grid.Column width="4">Floor</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[27].value === true}
                                onClick={(e) => updateHandler(27, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[27].value === false}
                                onClick={(e) => updateHandler(27, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(27, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Walls</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[28].value === true}
                                onClick={(e) => updateHandler(28, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[28].value === false}
                                onClick={(e) => updateHandler(28, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(28, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Ceiling</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[29].value === true}
                                onClick={(e) => updateHandler(29, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[29].value === false}
                                onClick={(e) => updateHandler(29, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(29, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Windows</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[30].value === true}
                                onClick={(e) => updateHandler(30, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[30].value === false}
                                onClick={(e) => updateHandler(30, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(30, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Lighting</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[31].value === true}
                                onClick={(e) => updateHandler(31, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[31].value === false}
                                onClick={(e) => updateHandler(31, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(31, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Outlets</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[32].value === true}
                                onClick={(e) => updateHandler(32, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[32].value === false}
                                onClick={(e) => updateHandler(32, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(32, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Doors</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[33].value === true}
                                onClick={(e) => updateHandler(33, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[33].value === false}
                                onClick={(e) => updateHandler(33, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(33, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Closets</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[34].value === true}
                                onClick={(e) => updateHandler(34, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[34].value === false}
                                onClick={(e) => updateHandler(34, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(34, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Sinks</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[35].value === true}
                                onClick={(e) => updateHandler(35, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[35].value === false}
                                onClick={(e) => updateHandler(35, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(35, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Shower</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[36].value === true}
                                onClick={(e) => updateHandler(36, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[36].value === false}
                                onClick={(e) => updateHandler(36, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(36, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Bathroom Doors</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[37].value === true}
                                onClick={(e) => updateHandler(37, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[37].value === false}
                                onClick={(e) => updateHandler(37, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(37, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Fan</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[38].value === true}
                                onClick={(e) => updateHandler(38, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[38].value === false}
                                onClick={(e) => updateHandler(38, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(38, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Other</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[39].value === true}
                                onClick={(e) => updateHandler(39, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[39].value === false}
                                onClick={(e) => updateHandler(39, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(39, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>

                        <Header>Other</Header>
                        <Grid.Row>
                            <Grid.Column width="4">Carbon Monoxide Detectors</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[40].value === true}
                                onClick={(e) => updateHandler(40, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[40].value === false}
                                onClick={(e) => updateHandler(40, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(40, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Smoke Alarms</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[41].value === true}
                                onClick={(e) => updateHandler(41, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[41].value === false}
                                onClick={(e) => updateHandler(41, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(41, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Air-Conditioning</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[42].value === true}
                                onClick={(e) => updateHandler(42, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[42].value === false}
                                onClick={(e) => updateHandler(42, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(42, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Water Heater</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[43].value === true}
                                onClick={(e) => updateHandler(43, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[43].value === false}
                                onClick={(e) => updateHandler(43, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(43, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Thermostat</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[44].value === true}
                                onClick={(e) => updateHandler(44, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[44].value === false}
                                onClick={(e) => updateHandler(44, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(44, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Doorbell</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[45].value === true}
                                onClick={(e) => updateHandler(45, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[45].value === false}
                                onClick={(e) => updateHandler(45, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(45, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Front Door</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[46].value === true}
                                onClick={(e) => updateHandler(46, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[46].value === false}
                                onClick={(e) => updateHandler(46, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(46, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Balcony</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[47].value === true}
                                onClick={(e) => updateHandler(47, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[47].value === false}
                                onClick={(e) => updateHandler(47, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(47, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="4">Other</Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Good'
                                checked={Checklist[48].value === true}
                                onClick={(e) => updateHandler(48, true)} /></Grid.Column>
                            <Grid.Column width="2"><Checkbox label='Poor'
                                checked={Checklist[48].value === false}
                                onClick={(e) => updateHandler(48, false)} /></Grid.Column>
                            <Grid.Column width="8"><Input fluid placeholder='Explain'
                                onChange={(e) =>
                                    textHandler(48, e.target.value)
                                } /></Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => SubmitForm()}
                    positive
                >Submit</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default MoveInChecklist