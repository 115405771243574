import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { US_STATES } from "../../utils/constants";
import LeaLoader  from "../Loader/LeaLoader";

export function BankAccountModal(props) {
  const [name, setNameValid] = React.useState("");
  const [account, setAccountValid] = React.useState("");
  const [routing, setRoutingValid] = React.useState("");
  const [address, setAddressValid] = React.useState("");
  const [city, setCityValid] = React.useState("");
  const [zip, setZipValid] = React.useState("");

  /* eslint-disable */
  const checkValid = () => {
    if (!name || (name && name.trim() == "")) {
      return true;
    }
    if (
      !account ||
      (account && account.trim() == "") ||
      (account && account.trim().length < 5)
    ) {
      return true;
    }
    if (
      !routing ||
      (routing && routing.trim() == "") ||
      (routing && routing.trim().length !== 9)
    ) {
      return true;
    }
    if (!address || (address && address.trim() == "")) {
      return true;
    }
    if (!city || (city && city.trim() == "")) {
      return true;
    }
    if (!zip || (zip && zip.trim() == "")) {
      return true;
    } else return false;
  };
  /* eslint-enable */
  //   dummy data
  return (
    <Modal
      open={props.showModal}
      onClose={() => props.toggleIsShowing(false)}
      className="semanticModal addbank-account-modal"
    >
      <form onSubmit={props.handleAddBankAccount}>
        <Modal.Header>
          Add Bank Account
          <img
            src="/assets/img/close-modal.svg"
            id="close"
            alt="close"
            onClick={() => props.toggleIsShowing(false)}
          ></img>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="form-group">
              <label>Name on account</label>
              <input
                type="text"
                onChange={(event) => {
                  setNameValid(event.target.value);
                }}
                name="name"
                id="name"
                className="form-control"
              ></input>
            </div>

            <div className="form-group">
              <label>Account Number</label>
              <input
                id="account"
                onChange={(event) => {
                  setAccountValid(event.target.value);
                }}
                type="number"
                name="account"
                className="form-control"
              ></input>
            </div>
            <div className="form-group">
              <label>Routing Number</label>
              <input
                // type="text" duplicate. Replace with prop below if number is not the desired input type.
                onChange={(event) => {
                  setRoutingValid(event.target.value);
                }}
                type="number"
                id="routing"
                name="routing"
                className="form-control"
              ></input>
            </div>
            <div className="form-group">
              <label>Usage Type</label>
              <br></br>
              <select
                style={{ border: "none", width: "100%" }}
                id="type"
                name="type"
              >
                <option value="personal">Personal</option>
                <option value="business">Business</option>
              </select>
            </div>
            <div className="form-group">
              <label>Account Type</label>
              <br></br>
              <select
                style={{ border: "none", width: "100%" }}
                id="accType"
                name="accType"
              >
                <option value="checking">Checking</option>
                <option value="saving">Saving</option>
              </select>
            </div>
            <div className="form-rw">
              <div className="form-group">
                <label>Billing Address Line</label>
                <input
                  type="text"
                  id="address1"
                  onChange={(event) => {
                    setAddressValid(event.target.value);
                  }}
                  name="address1"
                  className="form-control"
                ></input>
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select
                  style={{ border: "none", width: "100%" }}
                  id="state"
                  name="state"
                >
                  {Object.keys(US_STATES).map((key) => {
                    return <option value={key}>{key}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="form-rw">
              <div className="form-group">
                <label>Billing City</label>
                <input
                  type="text"
                  id="city"
                  onChange={(event) => {
                    setCityValid(event.target.value);
                  }}
                  name="city"
                  className="form-control"
                ></input>
              </div>
              <div className="form-group">
                <label>Billing Address ZIP Code</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setZipValid(event.target.value);
                  }}
                  name="zip"
                  id="zip"
                  className="form-control"
                ></input>
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            disabled={checkValid() || props.process}
            type="submit"
          >
            {props.process ?
              <LeaLoader wrapperStyle={{position: 'relative', left: '-10px', bottom: '16px'}} logoStyle={{height: '60px', width: '60px'}}/>
            :
              'Add Bank Account'
            }
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
}

export default BankAccountModal;
