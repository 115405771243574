import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { getClient } from "../../../store/auth/init-apollo-googleFn";
import { CHAT_URL, CHAT_URL_IMAGE_PATH, encodeBase64ID } from "../../../utils";
import { createChatRoomGql } from "../../../store/chat/chat";
import { ADD_CHAT_ROOM } from "../../../store/redux/chat";
import { PageLoader } from "../../../components/Loader/PageLoader";

import "./NewConversationModal.scss";

const chatApi = getClient(CHAT_URL);

const Content = ({ dismissModal }) => {
  const [search, setSearch] = React.useState("");
  const [redirect, setRedirect] = React.useState(null);
  const { chat, person } = useSelector((store) => store);
  const [selectedContact, setSelectedContact] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  let contactList = chat.contactList;
  const regex = new RegExp(search, "gi");

  if (search.length > 0) {
    contactList = chat.contactList.filter((contact) =>
      contact.title.match(regex)
    );
  }

  const createChatRoom = async ({
    title,
    label,
    avatar,
    locationId,
    personId,
  }) => {
    setLoading(true);

    const packageData = {
      creatorId: person.ndbId,
      memberId: personId,
      locationId,
      listMemberId: [person.ndbId, personId],
      name: title,
      isGroup: false,
    };

    try {
      const createChatRoomMutation = await chatApi.mutate({
        mutation: createChatRoomGql,
        variables: { package: packageData },
      });

      const roomId = createChatRoomMutation?.data?.createChatRoom.response;
      dispatch({
        type: ADD_CHAT_ROOM,
        payload: {
          creatorId: person.ndbId,
          roomTitle: title,
          label: label,
          avatar: avatar,
          chatRoomId: roomId,
        },
      });

      setRedirect(`/messages/chat/${roomId}`);
    } catch (error) {
      console.log("[CHAT] create room mutation error:", error);
    } finally {
      setLoading(false);
    }
  };

  const startContersation = () => {
    if (selectedContact !== null) {
      createChatRoom(selectedContact);
      // dismissModal();
    }
  };

  if (redirect !== null) return <Redirect to={redirect} />;
  if (loading) return <PageLoader />;

  return (
    <div className="chat-modal-content">
      <div className="title">New Conversation</div>
      <div className="description">
        Please select the user to whom you want to contact.
      </div>

      <div className="text-input">
        <label for="search-name">Search Name</label>
        <input
          type="text"
          id="search-name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="search-name"
          placeholder="Search by name"
        />
      </div>

      <div className="list">
        {contactList.map(
          ({ personId, title, avatar, label, locationId }, key) => {
            let classNames = ["contact-item"];

            if (selectedContact?.personId === personId)
              classNames.push("active");

            const contactData = {
              personId,
              title,
              avatar,
              label,
              locationId: encodeBase64ID("Location", locationId),
            };

            return (
              <div
                key={key}
                className={classNames.join(" ")}
                onClick={() => setSelectedContact(contactData)}
              >
                <img src={`${CHAT_URL_IMAGE_PATH}${avatar}`} alt="" />
                <span className="name">{title}</span>
                <span className="chat-contact-label" type={label}>
                  {label.replaceAll("_", " ").toUpperCase()}
                </span>
              </div>
            );
          }
        )}
      </div>

      <Button onClick={() => startContersation()} className="btn btn-gradient">
        START CONVERSATION
      </Button>
    </div>
  );
};

const NewConversationModal = ({ visible, setVisible }) => {
  return (
    <Modal
      className={`semanticModal new-conversation-modal`}
      style={{ backgroundColor: "#f8f8f8" }}
      size="small"
      onClose={() => setVisible(false)}
      onOpen={() => setVisible(true)}
      open={visible}
    >
      <Modal.Content style={{ backgroundColor: "#f8f8f8" }}>
        <Content {...{ dismissModal: () => setVisible(false) }} />
      </Modal.Content>
    </Modal>
  );
};

export default NewConversationModal;
