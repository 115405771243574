import React, { Component } from "react";
import "./Markets.scss";
import { withApollo } from "react-apollo";
import "../../assets/fomantic/dist/semantic.css";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  


  render() {

    return (
      <>
        <iframe style={{height:'100%', width:'100%'}} src="https://leasera-21167450.hs-sites.com/leasera-perks-0" ></iframe>
      </>
    );
  }
}

export default withApollo(Services);
