import React, { Component } from "react";
import {
  PROCESSING,
  OPEN_APPLICATION,
  COMPLETED,
  FAILED,
} from "../../utils/constants";
import "./BackgroundCheckModal.scss";

class BackgroundCheckModal extends Component {
  redirectToApplication = () => {
    this.props.history.push(`/profile?${OPEN_APPLICATION}`);
  };

  render() {
    const { bgCheck } = this.props;

    const backgroundCheckMessage = () => {
      switch (this.props.bgCheck.taApplicationStatus) {
        case PROCESSING:
          return "Your credit & background check is processing";
        case FAILED:
          return "Your credit & background check had problems and needs your attention";
        case COMPLETED:
          return " ";
        default:
          break;
      }
    };

    return (
      <div
        className="modal fade bd-example-modal-sizes background-check-modal__container"
        id="bgCheckModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cardModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Important
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-message">
                <div className="modal-message--title">
                  This property requires a credit & background check to apply.
                </div>
                {bgCheck.taApplicationStatus !== null && (
                  <div className="modal-message--secondary">
                    {backgroundCheckMessage()}
                  </div>
                )}
                {bgCheck.taApplicationStatus === PROCESSING && (
                  <div className="modal-message--tertiary">
                    You should receive an email when its completed. Please try
                    again later.
                  </div>
                )}
                {bgCheck.taApplicationStatus === FAILED && (
                  <div className="modal-message--tertiary">
                    <a href="#/bgchecks">Click here</a> to resolve your issues.
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => this.props.addToFavs()}
              >
                Add To Favorites!{" "}
              </button>
              {!bgCheck.taApplicationStatus && (
                <button
                  type="button"
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={() => this.redirectToApplication()}
                >
                  {" "}
                  Get a background check{" "}
                </button>
              )}
              <button
                type="button"
                className="btn btn-light"
                data-dismiss="modal"
              >
                {" "}
                Close{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BackgroundCheckModal;
