import React, { Component } from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Icon,
  Message,
} from "semantic-ui-react";
import "./Applications.scss";
import toaster from "../../store/utils/toaster";
import { EMAIL_REGEX } from "../../utils/constants";

class CoApplicants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      enableCheckbox: false,
      applicantIndex: 0,
      statesList: [],
      address: this.props.application,
      errors: [],
    };
  }

  coApplicantValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      if (ele.firstName === "" || ele.lastName === "" || ele.email === "") {
        return (valueToReturn = false);
      }
    });
    return valueToReturn;
  };

  invalidEmailCheck = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      if (ele.email !== "" && !EMAIL_REGEX.test(ele.email)) {
        return (valueToReturn = false);
      }
    });
    return valueToReturn;
  };

  /* function that validates the data captured, if data is valid, 
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    const { updateFormSteps } = this.props;
    let updatedErrors = [];
    if (!this.coApplicantValidation()) {
      updatedErrors.push({
        field: "Co-Applicant Details",
        message: "Please fill all required co-applicant details",
      });
    }
    if (!this.invalidEmailCheck()) {
      updatedErrors.push({
        field: "Email",
        message: "Please enter a valid email",
      });
    }
    if (!this.props.ageCheckbox) {
      updatedErrors.push({
        field: "Terms And Conditions",
        message: "Agree to the age condition",
      });
    }
    this.setState({ errors: updatedErrors });
    if (!updatedErrors.length) {
      updateFormSteps(3);
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex, applicantIndex: index });
    if (this.props.application[index].isFinanciallyResponsible) {
      this.setState({ enableCheckbox: true });
    } else {
      this.setState({ enableCheckbox: false });
    }
  };

  updateEmploymentStatus = (checked, index) => {
    const { application } = this.props;
    const { enableCheckbox, applicantIndex } = this.state;
    this.setState({ enableCheckbox: checked, applicantIndex: index }, () => {
      this.props.setCoApplicantDirectFields(
        "isFinanciallyResponsible",
        checked,
        index,
        "coApplicants"
      );
      this.setState({ state: this.state });
    });
    if (!enableCheckbox) {
      this.props.application[applicantIndex].employmentHistory =
        application[applicantIndex].employmentHistory;
      this.props.application[this.state.applicantIndex].otherIncomes =
        application[applicantIndex].otherIncomes;
    }
  };

  dropdownGroup = (application, activeIndex, setCoApplicantDirectFields) => {
    return (
      <>
        <Accordion fluid styled className="custom-accordian mt-25">
          {application.map((ele, index) => (
            <>
              <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={this.handleClick}
              >
                {`Co-Applicant ${index + 1}`}
                <Icon name="chevron right" className="mr-0" />
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                <Form className="co-applicant-form mt-0">
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      className="height-38"
                      required
                      label="First Name"
                      value={application[index].firstName}
                      placeholder="First Name"
                      onChange={(e) =>
                        setCoApplicantDirectFields(
                          "firstName",
                          e.target.value,
                          index,
                          "coApplicants"
                        )
                      }
                    />
                    <Form.Input
                      fluid
                      className="height-38"
                      required
                      label="Last name"
                      placeholder="Last name"
                      value={application[index].lastName}
                      onChange={(e) =>
                        setCoApplicantDirectFields(
                          "lastName",
                          e.target.value,
                          index,
                          "coApplicants"
                        )
                      }
                    />
                    <Form.Input
                      fluid
                      className="height-38"
                      required
                      label="Email"
                      placeholder="Email"
                      value={application[index].email}
                      onChange={(e) =>
                        setCoApplicantDirectFields(
                          "email",
                          e.target.value,
                          index,
                          "coApplicants"
                        )
                      }
                    />
                  </Form.Group>
                  <Checkbox
                    className="mt-3"
                    checked={application[index].isFinanciallyResponsible}
                    onChange={() =>
                      this.updateEmploymentStatus(
                        !application[index].isFinanciallyResponsible,
                        index
                      )
                    }
                    label="Co-Applicant is Financially Responsible?"
                  ></Checkbox>
                </Form>
              </Accordion.Content>
            </>
          ))}
        </Accordion>
        <Checkbox
          className="mt-3"
          checked={this.props.ageCheckbox}
          onChange={() => this.props.setLocalAgeState()}
          label="I agree that all co-applicants are at least 18 years of age"
        ></Checkbox>
      </>
    );
  };

  render() {
    const { application, setCoApplicantDirectFields } = this.props;
    const { activeIndex } = this.state;
    return (
      <>
        {this.dropdownGroup(
          application,
          activeIndex,
          setCoApplicantDirectFields
        )}
        {!!this.state.errors.length && (
          <Message visible error>
            <Message.List>
              {this.state.errors.map((item, index) => (
                <Message.Item
                  key={index}
                >{`${item.field} - ${item.message}`}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}
        <div className="text-right mt-4">
          <Button
            positive
            className="modal-save-button wid-110"
            onClick={() => this.props.updateFormSteps(0)}
          >
            Previous
          </Button>
          <Button
            positive
            className="modal-save-button mr-0 ml-2 wid-110"
            content="Next"
            onClick={(e) => this.validateAndSubmit(e)}
          />
          {toaster()}
        </div>
      </>
    );
  }
}
export default CoApplicants;
