import React from "react";
import {
  Form,
  Button,
  Modal,
  TextArea,
  Header,
  Dropdown,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import LeaLoader from "../Loader/LeaLoader";

function CancelModal ({
    cancelModalOpen,
    cancelSubmit,
    fromModal,
    handleCancelModal,
    modalData,
    isSubmitting
  }) {

  return (
    <>
      <Modal
        className="semanticModal add-lead-modal"
        onClose={() => handleCancelModal(false)}
        onOpen={()=> handleCancelModal(true)}
        open={cancelModalOpen}
        size="tiny"
        trigger={
            <Dropdown.Item
              onClick={() => handleCancelModal(true)}
              className={fromModal ? "btn btn-secondary" : "cancel-txt"}
              text="Cancel Request"
            />
        }
      >
        <Modal.Header className="modal-header-bg">
          <Header
            textAlign="left"
            className="modal-heading-custom position-sticky"
          >
            Cancel Request
          </Header>
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={(e) => cancelSubmit(e, modalData)} style={{ height: "100px", width: '493', margin: "0 auto" }}>
            <TextArea
              style={{ height: "100px" }}
              placeholder="Please let us know why you are canceling this request."
              name="reason"
            />
            <Button
              style={{ background: "#4C158A", height: '35.99px', color: "#F2FCFB", position: "absolute", right: '-4px', bottom: '-51px', zIndex: 2}}
              type="submit"
              content="Submit"
            >
              {isSubmitting ?
                <LeaLoader logoStyle={{height: '40px', position: 'relative', bottom: '12px'}}/>
              :
                'Cancel'
              }
            </Button>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ background: "#343c49", color: "#F2FCFB", marginRight: '100px' }}
            onClick={() => handleCancelModal(false)}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
export default withApollo(CancelModal);
