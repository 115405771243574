export const propertyPlace = `place {
    appliances
    available
    bathrooms
    bedrooms
    created
    cleaningDeposit
    description
    exFromOcc
    hearingAcc
    holdingUnit
    leaOriginFee
    maxOccupancy
    mobilityAcc
    onlineMrkt
    petsPermitted
    photos {
      url
    }
    price
    securityDeposit
    size
    tax
    updated
    unitType
    visualAcc
  }`;
// walkScore {
//   walk
//   transit
//   bike
// }

export const propertyLocation = `location {
    city
    houseNumber
    lat
    lng
    name
    postcode
    state
    streetOne
    streetTwo
  }`;

export const locationAddressKey = `address {
    streetOne
    streetTwo
    city
    state
    postcode
    yearBuilt
    lat
    lng
  }`;
