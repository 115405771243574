import gql from "graphql-tag";
// import { propertyPlace, propertyLocation } from '../utils/propertiesKeys'

export const personGql = gql`
  query Person {
    person {
      edges {
        node {
          ndbId
          id
          bio
          photoPath
          firstName
          lastName
          middleName
          dateOfBirth
          email
          phoneHome
          phoneMobile
          balance
          verificationType
          verificationNumber
          pointsBalance
          criminalEviction {
            isConvictedOfFelony
            isConvictedOfMisdemeanor
            isEvicted
          }
          reference {
            ndbId
            phone
            title
            name
            email
          }
          employment {
            employerName
            current
            title
            salary
            supervisorName
            supervisorEmail
            supervisorPhone
            startDate
            endDate
            address {
              street
              city
              state
              country
              postcode
            }
            ndbId
          }
          identification {
            country
            number
            stateIssued
          }
          addresses {
            street
            postcode
            city
            state
            country
            ndbId
            current
            googlePlacesInfo
          }

          residence {
            relocationReason
            endDate
            price
            managerId
            manager {
              name
              phone
              ndbId
            }
            addresId
            address {
              street
              city
              state
              postcode
              country
              ndbId
            }
            startDate
            desc
            ndbId
          }
        }
      }
    }
  }
`;

export const pointsGql = gql`
  query Person {
    person {
      edges {
        node {
          pointsBalance
        }
      }
    }
  }
`;
export const notificationsGql = gql`
  query personNotifications {
    personNotifications {
      edges {
        node {
          ndbId
          created
          updated
          message
          read
        }
      }
    }
  }
`;

// favorites {
//   isFavourite
//   dateListed
//   rooms {
//     roomsAvailable
//   }
//   ${propertyPlace}
//   ${propertyLocation}
//   points
//   id
//   ndbId
// }
export const updatePersonGql = gql`
  mutation updatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      person {
        firstName
        lastName
        middleName
      }
      clientMutationId
    }
  }
`;

export const verifyPerson = gql`
  mutation verifyPersonEmail($input: VerifyPersonEmailInput!) {
    verifyPersonEmail(input: $input) {
      response
    }
  }
`;

export const updatePhotoGql = gql`
  mutation updatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      person
    }
  }
`;

// export const createAddressGql = gql`
//   mutation updateAddress($input: UpdateAddressInput!) {
//     createAddress(input: $input) {
//       response
//     }
//   }
// `

export const updatePersonPhotoGql = gql`
  mutation updatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      response
    }
  }
`;

// export const updateEmploymentGql = gql`
//   mutation updateEmployment($input: UpdateEmploymentHistInput!) {
//     updateEmploymentHist(input: $input) {
//       person {
//         firstName
//         lastName
//       }
//     }
//   }
// `

// export const createEmploymentGql = gql`
//   mutation createEmployment($input: CreateEmploymentHistInput!) {
//     createEmploymentHist(input: $input) {
//       response
//     }
//   }
// `

// export const createRentalHistGql = gql`
//   mutation createRentalHist($input: CreateRentalHistInput!) {
//     createRentalHist(input: $input) {
//       response
//     }
//   }
// `

// export const updateRentalHistGql = gql`
//   mutation updateRentalHist($input: UpdateRentalHistInput!) {
//     updateRentalHist(input: $input) {
//       response
//     }
//   }
// `

// export const createReferenceGql = gql`
//   mutation createReference($input: CreateReferenceInput!) {
//     createReference(input: $input) {
//       response
//     }
//   }

// `

// export const updateReferenceGql = gql`
//   mutation updateReference($input: UpdateReferenceInput!) {
//     updateReference(input: $input) {
//       response
//     }
//   }

// `

export const uploadBioPhotoGql = gql`
  mutation uploadBioPhoto($input: UploadBioPhotoInput!) {
    uploadBioPhoto(input: $input) {
      success
    }
  }
`;

export const FCMAttachDevice = gql`
  mutation attachFcmDevice($input: FCMAttachDeviceInput!) {
    attachFcmDevice(input: $input) {
      response
    }
  }
`;

export const UnloadFCMAttachDevice = gql`
  mutation detachFcmDevice($input: FCMDetachDeviceInput!) {
    detachFcmDevice(input: $input) {
      response
    }
  }
`;

export const QueryOffers = gql`
  query personServices {
    personServices {
      edges {
        node {
          ndbId
          created
          updated
          logo
          title
          price
          link
          points
          priceFreq
          desc
          colorCode
          priority
          site
          category
          revPotential
        }
      }
    }
  }
`;

export const eventsGql = gql`
  query personCalendar {
    personCalendar {
      edges {
        node {
          id
          personId
          locationId
          eventName
          eventType
          eventId
          description
          startDate
          endDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;
