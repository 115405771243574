import React, { useState } from "react";
import { Button, Modal, Header, Segment, Icon } from "semantic-ui-react";
import { singleLeaseGql, RecipientView,CreateEnv } from '../../store/person/leases';
import { DATE_FORMAT, DOCUSIGN } from '../../utils';
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { PageLoader } from "../../components/Loader/PageLoader";


const LeaseApi = getClient(DOCUSIGN)
export default function ReviewLeaseModal(props) {
  const [open, setOpen] = useState(false);
  const [openURL, setURL] = useState(false);
  const [loader, setLoader] = useState(false);

  const getLease = () => {
    setOpen(true)
    setLoader(true)
    try {
      LeaseApi
        .mutate({
          mutation:RecipientView,
          variables: {
            input: {
              leaseId: props.leaseId
            },
          },
        })
        .then((response) => {
          const rec = response.data.recipientView.docusign.url
          setURL(rec)
          setLoader(false)
        })
        .catch((e) => {
          setLoader(false)
          setURL(false)
        });
    } catch (e) {
      setLoader(false)
      setURL(false)
    }
  };



  return (
    <Modal
      className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="fullscreen"
      style={{height:"85%"}}
      open={open}
      trigger={<Button className="btn next-btn" onClick={() => getLease()}>Continue to Sign</Button>}
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          Review Lease
        </Header>
      </Modal.Header>
      <Modal.Content style={{height:"85%", paddingLeft:"14px"}} >
        {
          loader ?
            <PageLoader text justify="center" />
            :
            <>
            {
              openURL ?
            <iframe style={{width:'100%', height:'100%'}} src={openURL} ></iframe>
            :
            <Segment placeholder style={{height:'100%'}}>
              <Header icon>
                <Icon name='pdf file outline' />
                No documents are listed for this customer.
              </Header>
            </Segment>
            }
            </>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Cancel"
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}
