import React, { Component } from "react";
import get from "lodash/get";
import { PageLoader } from "../../components/Loader/PageLoader";
import {
  applicationGql,
  coApplicationGql,
} from "../../store/person/applications";
import {
  Divider,
  Header,
  Accordion,
  Breadcrumb,
  Icon,
  Label,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { BG_CHECK_URL } from "../../utils/constants";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { backgroundCheckGql } from "../../store/person/backgroundCheck";

const BGC = getClient(BG_CHECK_URL);

class Applications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      application: "",
      ssn: "",
      ssnInfo: "",
      ActivePay: false,
      loading: false,
      showPassword: false,
      onOpen: false,
      terms: false,
      primaryActiveIndex: 0,
      coAppActiveIndex: 0,
      occupantActiveIndex: 0,
      vehicleActiveIndex: 0,
      petsActiveIndex: 0,
      activeIndex: 0,
    };
  }

  componentDidMount() {
    this.fetchApplications();
    this.backgroundCheck();
  }

  // Fetch the Applications
  fetchApplications = () => {
    this.setState({ loading: true });
    if (this.props.match.params.applicationId.includes("coApp")) {
      try {
        this.props.applicationManager
          .query({
            query: coApplicationGql,
            variables: {
              applicationId: this.props.match.params.applicationId.replace(
                "coApp",
                ""
              ),
            },
          })
          .then((response) => {
            this.setState({ loading: false });
            const applications = get(response, "data.appAsCo.edges[0].node");
            applications.coApp = true;
            this.setState({ application: applications });
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        this.props.applicationManager
          .query({
            query: applicationGql,
            variables: {
              applicationId: this.props.match.params.applicationId,
            },
          })
          .then((response) => {
            this.setState({ loading: false });
            const application = get(
              response,
              "data.appAsPrimary.edges[0].node"
            );
            this.setState({ application: application });
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // Background Check Query
  backgroundCheck = () => {
    BGC.query({
      query: backgroundCheckGql,
    })
      .then((results) => {
        if (results.data) {
          const dates = results.data.backgroundChecks.edges;
          const BGdateArray = [];
          dates.forEach((date) => {
            if (
              (moment(date.node.submitted).isBetween(
                moment().subtract(31, "days"),
                moment()
              ) &&
                /* eslint-disable */
                date.node.taApplicationStatus === "Approved") ||
              date.node.taApplicationStatus === "Processing"
            ) {
              BGdateArray.push(date);
              /* eslint-enable */
            }
          });
          this.setState({ BGdateArray: BGdateArray });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // Toggle Show/Hide SSN
  onTogglePassword = () => {
    if (this.state.showPassword === false) {
      this.setState({ showPassword: true });
    } else {
      this.setState({ showPassword: false });
    }
  };
  handleClick = (e, titleProps) => {
    const { index, type } = titleProps;
    this.setState({
      primaryActiveIndex: -1,
      coAppActiveIndex: -1,
      occupantActiveIndex: -1,
      vehicleActiveIndex: -1,
      petsActiveIndex: -1,
    });
    const newIndex = this.state[type] === index ? -1 : index;
    this.setState({ [type]: newIndex });
  };
  setSSN = (e) => {
    this.setState({ ssn: e });
    this.setState({ ssnInfo: e.replace(/-/g, "") });
  };
  ssnChecker = (e) => {
    if (this.state.ssn === e) {
      this.setState({ ActivePay: true });
    } else {
      this.setState({ ActivePay: false });
    }
  };

  handleClose = () => this.setState({ modalOpen: false });

  onOpen = () => {
    this.setState({ onOpen: true });
  };
  onClose = () => {
    this.setState({ onOpen: false, terms: true });
  };
  onCancel = () => {
    this.setState({ onOpen: false });
  };
  handleCheck = () => {
    if (this.state.terms === false) {
      this.setState({ terms: true });
    } else {
      this.setState({ terms: false });
    }
  };

  getFullAddress = (address) => {
    return `${address.streetLine1 ? address.streetLine1 : address.streetOne},
    ${address.city},
    ${address.state},
    ${address.postcode ? address.postcode : address.zip}`;
  };

  requirements = () => {
    const {
      application,
      primaryActiveIndex,
      coAppActiveIndex,
      occupantActiveIndex,
      vehicleActiveIndex,
      petsActiveIndex,
    } = this.state;
    return (
      <>
        <div className="form-data-wrapper">
          <div className="form-data-col">
            <p className="form-data-label">Floor Plan</p>
            <p className="form-data-text">
              {application.unitType ? application.unitType.type : "NA"} - Beds:{" "}
              {application.unitType ? application.unitType.bedrooms : " NA"} /
              Bath:{" "}
              {application.unitType ? application.unitType.bathrooms : " NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Unit Number</p>
            <p className="form-data-text">
              Unit:{" "}
              {application.unitNumber ? application.unitNumber.number : " NA"} /
              Floor:{" "}
              {application.unitNumber && application.unitNumber.level
                ? application.unitNumber.level
                : " NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Affordable Housing Program</p>
            <p className="form-data-text">
              {application.affordableHousingProgram
                ? application.affordableHousingProgram.code
                : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Leasing Agent</p>
            <p className="form-data-text">
              {application.leasingAgent
                ? application.leasingAgent.firstName +
                  " " +
                  application.leasingAgent.lastName
                : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Move In date</p>
            <p className="form-data-text">
              {application.targetMoveInDate
                ? application.targetMoveInDate
                : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Liquid Filled Furniture?</p>
            <p className="form-data-text">
              {application.liquidFilledFurniture ? "Yes" : "No"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Adults</p>
            <p className="form-data-text">{application.adults}</p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Children</p>
            <p className="form-data-text">{application.children}</p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Pets</p>
            <p className="form-data-text">{application.petsNumber}</p>
          </div>
          <div className="form-data-col ">
            <p className="form-data-label">Does any occupant smoke?</p>
            <p className="form-data-text">{application.smoke ? "Yes" : "No"}</p>
          </div>
        </div>

        <Accordion fluid styled className="custom-accordian mt-25">
          {application.coApp && (
            <>
              <Accordion.Title
                active={primaryActiveIndex === 0}
                index={0}
                type="primaryActiveIndex"
                onClick={this.handleClick}
              >
                <span className="first-lastname-text">
                  {application.myDetails && application.myDetails.firstName}{" "}
                  {application.myDetails && application.myDetails.lastName}
                </span>
                <div>
                  <span className="co-applicant-text">Your Details</span>
                  <Icon name="chevron right" className="mr-0" />
                </div>
              </Accordion.Title>
              <Accordion.Content
                active={primaryActiveIndex === 0}
                className="p-0"
              >
                <div className="applicant-info-box">
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Mobile Phone</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.phoneMobile}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Email</p>
                    <p className="applicant-info-text">
                      {application.myDetails && application.myDetails.email}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Government ID Type</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.drivingLicence &&
                        application.myDetails.drivingLicence.idType}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Drivers License/ID #</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.drivingLicence &&
                        application.myDetails.drivingLicence.number}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Issuing State</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.drivingLicence &&
                        application.myDetails.drivingLicence.issuingState}
                      {application &&
                        application.drivingLicence &&
                        application.drivingLicence.issuingState}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Date of Birth</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                      application.myDetails.dateOfBirth
                        ? application.myDetails.dateOfBirth
                        : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Marital Status</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                      application.myDetails.maritalStatus
                        ? application.myDetails.maritalStatus
                        : "NA"}
                    </p>
                  </div>
                </div>
                {!application.coApp && (
                  <>
                    <div className="review-address-heading">
                      Address Details
                    </div>
                    {application &&
                      application.myDetails &&
                      application.myDetails.rentalHistory.map((ele, index) => (
                        <>
                          <p className="review-address-paragraph">
                            Address {index + 1}
                          </p>
                          <div className="applicant-info-box">
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">Address</p>
                              <p className="applicant-info-text">
                                {this.getFullAddress(ele.address)}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Monthly Rent
                              </p>
                              <p className="applicant-info-text">
                                {ele.monthlyRent ? ele.monthlyRent : "NA"}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Duration of Residence
                              </p>
                              <p className="applicant-info-text">
                                {ele.startDate +
                                  "-" +
                                  (ele.endDate
                                    ? ele.endDate
                                    : "(Currently Residing)")}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Landlord Name
                              </p>
                              <p className="applicant-info-text">
                                {ele.landlordName}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Landlord Email
                              </p>
                              <p className="applicant-info-text">
                                {ele.landlordEmail ? ele.landlordEmail : "NA"}
                              </p>
                            </div>
                            <div className="applicant-info-col border-0">
                              <p className="applicant-info-label">
                                Landlord Phone
                              </p>
                              <p className="applicant-info-text">
                                {ele.landlordPhone ? ele.landlordPhone : "NA"}
                              </p>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                )}
                {!application.coApp && (
                  <>
                    <div className="review-address-heading">
                      Employment Details
                    </div>
                    {application.primaryApplicant &&
                      application.primaryApplicant.employmentHistory.map(
                        (ele, index) => (
                          <>
                            <p className="review-address-paragraph">
                              Employment {index + 1}
                            </p>
                            <div className="applicant-info-box">
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">Address</p>
                                <p className="applicant-info-text">
                                  {this.getFullAddress(ele.address)}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Occupation
                                </p>
                                <p className="applicant-info-text">
                                  {ele.title}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Employer/Company
                                </p>
                                <p className="applicant-info-text">
                                  {ele.employerName}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Monthly Income
                                </p>
                                <p className="applicant-info-text">
                                  {ele.monthlyIncome}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Duration of Employment
                                </p>
                                <p className="applicant-info-text">
                                  {ele.startDate +
                                    "-" +
                                    (ele.endDate
                                      ? ele.endDate
                                      : "(Currently Working)")}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Name
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorName}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Email
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorEmail
                                    ? ele.supervisorEmail
                                    : "NA"}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Phone
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorPhone
                                    ? ele.supervisorPhone
                                    : "NA"}
                                </p>
                              </div>
                            </div>
                          </>
                        )
                      )}
                  </>
                )}
                {!application.coApp && (
                  <>
                    <div className="review-address-heading">Other Incomes</div>
                    {application.primaryApplicant &&
                      application.primaryApplicant.otherIncomes &&
                      application.primaryApplicant.otherIncomes.map(
                        (ele, index) => (
                          <div className="applicant-info-box">
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">Source</p>
                              <p className="applicant-info-text">
                                {ele.source ? ele.source : "NA"}
                              </p>
                            </div>
                            <div className="applicant-info-col border-0">
                              <p className="applicant-info-label">
                                Monthly Income
                              </p>
                              <p className="applicant-info-text">
                                {ele.monthlyIncome ? ele.monthlyIncome : "NA"}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </>
                )}
                {application.coApp && (
                  <>
                    <div className="review-address-heading">
                      Address Details
                    </div>
                    {application &&
                      application.myDetails &&
                      application.myDetails.rentalHistory.map((ele, index) => (
                        <>
                          <p className="review-address-paragraph">
                            Address {index + 1}
                          </p>
                          <div className="applicant-info-box">
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">Address</p>
                              <p className="applicant-info-text">
                                {this.getFullAddress(ele.address)}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Monthly Rent
                              </p>
                              <p className="applicant-info-text">
                                {ele.monthlyRent ? ele.monthlyRent : "NA"}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Duration of Residence
                              </p>
                              <p className="applicant-info-text">
                                {ele.startDate +
                                  "-" +
                                  (ele.endDate
                                    ? ele.endDate
                                    : "(Currently Residing)")}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Landlord Name
                              </p>
                              <p className="applicant-info-text">
                                {ele.landlordName}
                              </p>
                            </div>
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">
                                Landlord Email
                              </p>
                              <p className="applicant-info-text">
                                {ele.landlordEmail ? ele.landlordEmail : "NA"}
                              </p>
                            </div>
                            <div className="applicant-info-col border-0">
                              <p className="applicant-info-label">
                                Landlord Phone
                              </p>
                              <p className="applicant-info-text">
                                {ele.landlordPhone ? ele.landlordPhone : "NA"}
                              </p>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                )}
                {application.coApp && (
                  <>
                    <div className="review-address-heading">
                      Employment Details
                    </div>
                    {application.myDetails &&
                      application.myDetails.employmentHistory.map(
                        (ele, index) => (
                          <>
                            <p className="review-address-paragraph">
                              Employment {index + 1}
                            </p>
                            <div className="applicant-info-box">
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">Address</p>
                                <p className="applicant-info-text">
                                  {this.getFullAddress(ele.address)}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Occupation
                                </p>
                                <p className="applicant-info-text">
                                  {ele.title}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Employer/Company
                                </p>
                                <p className="applicant-info-text">
                                  {ele.employerName}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Monthly Income
                                </p>
                                <p className="applicant-info-text">
                                  {ele.monthlyIncome}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Duration of Employment
                                </p>
                                <p className="applicant-info-text">
                                  {ele.startDate +
                                    "-" +
                                    (ele.endDate
                                      ? ele.endDate
                                      : "(Currently Working)")}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Name
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorName}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Email
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorEmail
                                    ? ele.supervisorEmail
                                    : "NA"}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Phone
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorPhone
                                    ? ele.supervisorPhone
                                    : "NA"}
                                </p>
                              </div>
                            </div>
                          </>
                        )
                      )}
                  </>
                )}
                {application.coApp && (
                  <>
                    <div className="review-address-heading">Other Incomes</div>
                    {application.myDetails &&
                      application.myDetails.otherIncomes &&
                      application.myDetails.otherIncomes.map((ele, index) => (
                        <div className="applicant-info-box">
                          <div className="applicant-info-col">
                            <p className="applicant-info-label">Source</p>
                            <p className="applicant-info-text">
                              {ele.source ? ele.source : "NA"}
                            </p>
                          </div>
                          <div className="applicant-info-col border-0">
                            <p className="applicant-info-label">
                              Monthly Income
                            </p>
                            <p className="applicant-info-text">
                              {ele.monthlyIncome ? ele.monthlyIncome : "NA"}
                            </p>
                          </div>
                        </div>
                      ))}
                  </>
                )}
                <div className="review-address-heading">Emergency Contact</div>
                <div className="applicant-info-box">
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Name</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.emergencyContact &&
                        application.myDetails.emergencyContact.name}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Mobile Phone</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.emergencyContact &&
                        application.myDetails.emergencyContact.mobilePhone}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Email</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.emergencyContact &&
                        application.myDetails.emergencyContact.email}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Relationship</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.emergencyContact &&
                        application.myDetails.emergencyContact.relationship}
                    </p>
                  </div>
                  <div className="applicant-info-col border-0">
                    <p className="applicant-info-label">Address</p>
                    <p className="applicant-info-text">
                      {application.myDetails &&
                        application.myDetails.emergencyContact &&
                        application.myDetails.emergencyContact.address
                          .streetLine1 +
                          " " +
                          application.myDetails.emergencyContact.address.city +
                          " " +
                          application.myDetails.emergencyContact.address.state +
                          " " +
                          application.myDetails.emergencyContact.address
                            .postcode}
                    </p>
                  </div>
                </div>
              </Accordion.Content>
            </>
          )}
          {/* Primary Application */}
          {application.primaryApplicant && (
            <>
              <Accordion.Title
                active={primaryActiveIndex === 10}
                index={10}
                type="primaryActiveIndex"
                onClick={this.handleClick}
              >
                <span className="first-lastname-text">
                  {application.primaryApplicant &&
                    application.primaryApplicant.firstName}{" "}
                  {application.primaryApplicant &&
                    application.primaryApplicant.lastName}
                </span>
                <div>
                  <span className="co-applicant-text">Primary Applicant</span>
                  <Icon name="chevron right" className="mr-0" />
                </div>
              </Accordion.Title>
              <Accordion.Content
                active={primaryActiveIndex === 10}
                className="p-0"
              >
                <div className="applicant-info-box">
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Mobile Phone</p>
                    <p className="applicant-info-text">
                      {application.primaryApplicant &&
                        application.primaryApplicant.phoneMobile}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Email</p>
                    <p className="applicant-info-text">
                      {application.primaryApplicant &&
                        application.primaryApplicant.email}
                    </p>
                  </div>
                  {!application.coApp && (
                    <>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">
                          Government ID Type
                        </p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.drivingLicence &&
                            application.primaryApplicant.drivingLicence.idType}
                        </p>
                      </div>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">
                          Drivers License/ID #
                        </p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.drivingLicence &&
                            application.primaryApplicant.drivingLicence.number}
                        </p>
                      </div>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">Issuing State</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.drivingLicence &&
                            application.primaryApplicant.drivingLicence
                              .issuingState}
                          {application &&
                            application.drivingLicence &&
                            application.drivingLicence.issuingState}
                        </p>
                      </div>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">Date of Birth</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                          application.primaryApplicant.dateOfBirth
                            ? application.primaryApplicant.dateOfBirth
                            : "NA"}
                        </p>
                      </div>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">Marital Status</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                          application.primaryApplicant.maritalStatus
                            ? application.primaryApplicant.maritalStatus
                            : "NA"}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                {!application.coApp && (
                  <>
                    <div className="review-address-heading">
                      Address Details
                    </div>
                    {application &&
                      application.primaryApplicant &&
                      application.primaryApplicant.rentalHistory.map(
                        (ele, index) => (
                          <>
                            <p className="review-address-paragraph">
                              Address {index + 1}
                            </p>
                            <div className="applicant-info-box">
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">Address</p>
                                <p className="applicant-info-text">
                                  {this.getFullAddress(ele.address)}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Monthly Rent
                                </p>
                                <p className="applicant-info-text">
                                  {ele.monthlyRent ? ele.monthlyRent : "NA"}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Duration of Residence
                                </p>
                                <p className="applicant-info-text">
                                  {ele.startDate +
                                    "-" +
                                    (ele.endDate
                                      ? ele.endDate
                                      : "(Currently Residing)")}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Landlord Name
                                </p>
                                <p className="applicant-info-text">
                                  {ele.landlordName}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Landlord Email
                                </p>
                                <p className="applicant-info-text">
                                  {ele.landlordEmail ? ele.landlordEmail : "NA"}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Landlord Phone
                                </p>
                                <p className="applicant-info-text">
                                  {ele.landlordPhone ? ele.landlordPhone : "NA"}
                                </p>
                              </div>
                            </div>
                          </>
                        )
                      )}
                  </>
                )}
                {!application.coApp && (
                  <>
                    <div className="review-address-heading">
                      Employment Details
                    </div>
                    {application.primaryApplicant &&
                      application.primaryApplicant.employmentHistory.map(
                        (ele, index) => (
                          <>
                            <p className="review-address-paragraph">
                              Employment {index + 1}
                            </p>
                            <div className="applicant-info-box">
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">Address</p>
                                <p className="applicant-info-text">
                                  {this.getFullAddress(ele.address)}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Occupation
                                </p>
                                <p className="applicant-info-text">
                                  {ele.title}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Employer/Company
                                </p>
                                <p className="applicant-info-text">
                                  {ele.employerName}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Monthly Income
                                </p>
                                <p className="applicant-info-text">
                                  {ele.monthlyIncome}
                                </p>
                              </div>
                              <div className="applicant-info-col">
                                <p className="applicant-info-label">
                                  Duration of Employment
                                </p>
                                <p className="applicant-info-text">
                                  {ele.startDate +
                                    "-" +
                                    (ele.endDate
                                      ? ele.endDate
                                      : "(Currently Working)")}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Name
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorName}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Email
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorEmail
                                    ? ele.supervisorEmail
                                    : "NA"}
                                </p>
                              </div>
                              <div className="applicant-info-col border-0">
                                <p className="applicant-info-label">
                                  Supervisor Phone
                                </p>
                                <p className="applicant-info-text">
                                  {ele.supervisorPhone
                                    ? ele.supervisorPhone
                                    : "NA"}
                                </p>
                              </div>
                            </div>
                          </>
                        )
                      )}
                  </>
                )}
                {!application.coApp && (
                  <>
                    <div className="review-address-heading">Other Incomes</div>
                    {application.primaryApplicant &&
                      application.primaryApplicant.otherIncomes &&
                      application.primaryApplicant.otherIncomes.map(
                        (ele, index) => (
                          <div className="applicant-info-box">
                            <div className="applicant-info-col">
                              <p className="applicant-info-label">Source</p>
                              <p className="applicant-info-text">
                                {ele.source ? ele.source : "NA"}
                              </p>
                            </div>
                            <div className="applicant-info-col border-0">
                              <p className="applicant-info-label">
                                Monthly Income
                              </p>
                              <p className="applicant-info-text">
                                {ele.monthlyIncome ? ele.monthlyIncome : "NA"}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </>
                )}
                {!application.coApp && (
                  <>
                    {application.primaryApplicant &&
                      application.primaryApplicant.backgroundChecks &&
                      application.primaryApplicant.backgroundChecks.checks &&
                      application.primaryApplicant.backgroundChecks.checks
                        .length > 0 && (
                        <>
                          <div className="review-address-heading">
                            Background Information
                          </div>
                          {this.showBackgroundInfo()}
                        </>
                      )}
                  </>
                )}
                {!application.coApp && (
                  <>
                    <div className="review-address-heading">
                      Emergency Contact
                    </div>
                    <div className="applicant-info-box">
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">Name</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.emergencyContact &&
                            application.primaryApplicant.emergencyContact.name}
                        </p>
                      </div>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">Mobile Phone</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.emergencyContact &&
                            application.primaryApplicant.emergencyContact
                              .mobilePhone}
                        </p>
                      </div>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">Email</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.emergencyContact &&
                            application.primaryApplicant.emergencyContact.email}
                        </p>
                      </div>
                      <div className="applicant-info-col">
                        <p className="applicant-info-label">Relationship</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.emergencyContact &&
                            application.primaryApplicant.emergencyContact
                              .relationship}
                        </p>
                      </div>
                      <div className="applicant-info-col border-0">
                        <p className="applicant-info-label">Address</p>
                        <p className="applicant-info-text">
                          {application.primaryApplicant &&
                            application.primaryApplicant.emergencyContact &&
                            application.primaryApplicant.emergencyContact
                              .address.streetLine1 +
                              " " +
                              application.primaryApplicant.emergencyContact
                                .address.city +
                              " " +
                              application.primaryApplicant.emergencyContact
                                .address.state +
                              " " +
                              application.primaryApplicant.emergencyContact
                                .address.postcode}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </Accordion.Content>
            </>
          )}
          {application.coApplicants &&
            application.coApplicants.length > 0 &&
            application.coApplicants.map((ele, index) => (
              <>
                <Accordion.Title
                  active={coAppActiveIndex === index + 1}
                  index={index + 1}
                  type="coAppActiveIndex"
                  onClick={this.handleClick}
                >
                  <span className="first-lastname-text">
                    {ele.firstName} {ele.lastName}
                  </span>
                  <div>
                    <span className="co-applicant-text">{`Co-Applicant ${
                      index + 1
                    }`}</span>
                    <Icon name="chevron right" className="mr-0" />
                  </div>
                </Accordion.Title>
                <Accordion.Content
                  active={coAppActiveIndex === index + 1}
                  className="p-0"
                >
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Mobile Phone</p>
                      <p className="applicant-info-text">
                        {ele.phoneMobile ? ele.phoneMobile : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Email</p>
                      <p className="applicant-info-text">
                        {ele.email ? ele.email : "NA"}
                      </p>
                    </div>
                  </div>
                </Accordion.Content>
              </>
            ))}
          {application.otherCoApplicants &&
            application.otherCoApplicants.length > 0 &&
            application.otherCoApplicants.map((ele, index) => (
              <>
                <Accordion.Title
                  active={coAppActiveIndex === index + 1}
                  index={index + 1}
                  type="coAppActiveIndex"
                  onClick={this.handleClick}
                >
                  <span className="first-lastname-text">
                    {ele.firstName} {ele.lastName}
                  </span>
                  <div>
                    <span className="co-applicant-text">{`Co-Applicant ${
                      index + 1
                    }`}</span>
                    <Icon name="chevron right" className="mr-0" />
                  </div>
                </Accordion.Title>
                <Accordion.Content
                  active={coAppActiveIndex === index + 1}
                  className="p-0"
                >
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Mobile Phone</p>
                      <p className="applicant-info-text">
                        {ele.phoneMobile ? ele.phoneMobile : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Email</p>
                      <p className="applicant-info-text">
                        {ele.email ? ele.email : "NA"}
                      </p>
                    </div>
                  </div>
                </Accordion.Content>
              </>
            ))}
          {application &&
            application.occupants &&
            application.occupants.map((ele, index) => (
              <>
                <Accordion.Title
                  active={occupantActiveIndex === index + 2}
                  index={index + 2}
                  type="occupantActiveIndex"
                  onClick={this.handleClick}
                >
                  <div className="ml-auto">
                    <span className="co-applicant-text">{`Occupant ${
                      index + 1
                    }`}</span>
                    <Icon name="chevron right" className="mr-0" />
                  </div>
                </Accordion.Title>
                <Accordion.Content active={occupantActiveIndex === index + 2}>
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">First Name</p>
                      <p className="applicant-info-text">{ele.firstName}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Last Name</p>
                      <p className="applicant-info-text">{ele.lastName}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Date of Birth</p>
                      <p className="applicant-info-text">{ele.dateOfBirth}</p>
                    </div>
                  </div>
                </Accordion.Content>
              </>
            ))}
          {application &&
            application.pets &&
            application.pets.map((ele, index) => (
              <>
                <Accordion.Title
                  active={petsActiveIndex === index + 3}
                  index={index + 3}
                  type="petsActiveIndex"
                  onClick={this.handleClick}
                >
                  <div className="ml-auto">
                    <span className="co-applicant-text">{`Pet ${
                      index + 1
                    }`}</span>
                    <Icon name="chevron right" className="mr-0" />
                  </div>
                </Accordion.Title>
                <Accordion.Content active={petsActiveIndex === index + 3}>
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Name</p>
                      <p className="applicant-info-text">{ele.name}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Pet Type</p>
                      <p className="applicant-info-text">{ele.petType}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Weight</p>
                      <p className="applicant-info-text">{ele.weight}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Color</p>
                      <p className="applicant-info-text">{ele.color}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Breed</p>
                      <p className="applicant-info-text">{ele.breed}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Gender</p>
                      <p className="applicant-info-text">{ele.gender}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Age</p>
                      <p className="applicant-info-text">{ele.age}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Service?</p>
                      <p className="applicant-info-text">
                        {ele.service ? "True" : "False"}
                      </p>
                    </div>
                  </div>
                </Accordion.Content>
              </>
            ))}
          {application &&
            application.vehicles &&
            application.vehicles.map((ele, index) => (
              <>
                <Accordion.Title
                  active={vehicleActiveIndex === index + 4}
                  index={index + 4}
                  type="vehicleActiveIndex"
                  onClick={this.handleClick}
                >
                  <div className="ml-auto">
                    <span className="co-applicant-text">{`Vehicle ${
                      index + 1
                    }`}</span>
                    <Icon name="chevron right" className="mr-0" />
                  </div>
                </Accordion.Title>
                <Accordion.Content active={vehicleActiveIndex === index + 4}>
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Make</p>
                      <p className="applicant-info-text">
                        {ele.make ? ele.make : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Model</p>
                      <p className="applicant-info-text">
                        {ele.model ? ele.model : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Year</p>
                      <p className="applicant-info-text">
                        {ele.year ? ele.year : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Color</p>
                      <p className="applicant-info-text">
                        {ele.color ? ele.color : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Licence Plate</p>
                      <p className="applicant-info-text">
                        {ele.licensePlate ? ele.licensePlate : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">State</p>
                      <p className="applicant-info-text">
                        {ele.state ? ele.state : "NA"}
                      </p>
                    </div>
                  </div>
                </Accordion.Content>
              </>
            ))}
        </Accordion>
      </>
    );
  };

  render() {
    const { application, loading } = this.state;
    const photos =
      application &&
      application.primaryLocation &&
      application.primaryLocation.photos;

    const Bread = () => (
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Section link>
          <NavLink exact to="/dashboard">
            Dashboard
          </NavLink>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active> {""}</Breadcrumb.Section>
      </Breadcrumb>
    );
    return (
      <>
        {loading ? (
          <PageLoader text wrapperStyle={{height: '150px', width: '150px'}} />
        ) : (
          <>
            <Bread />
            <div className="lead-profile-head">
              <Header as="h1">
                {application.primaryLocation &&
                  application.primaryLocation.name}
              </Header>
              <p className="application-status">
                {application && application.status}
              </p>
            </div>

            <div className="lead-profile-body">
              <div className="lead-profile-body-img">
                <Carousel
                  interval={"5000"}
                  showThumbs={false}
                  dynamicHeight={false}
                  autoFocus={true}
                  infiniteLoop={true}
                  autoPlay={true}
                >
                  {photos &&
                    photos.length !== 0 &&
                    photos.map((photo) => {
                      return (
                        <div style={{ height: "200px", width: "50px" }}>
                          <img src={photo} style={{ width: "400px" }} alt="" />
                        </div>
                      );
                    })}
                </Carousel>
                {photos && photos.length === 0 && (
                  <Carousel
                    showThumbs={false}
                    dynamicHeight={false}
                    autoFocus={true}
                    infiniteLoop={true}
                    autoPlay={true}
                  >
                    <div
                      style={
                        {
                          // height: "400px",
                          // overflowY: "hidden",
                        }
                      }
                    >
                      <img
                        src="/assets/img/chooseHome.svg"
                        alt=""
                        style={{
                          width: "40%",
                          height: "40%",
                          paddingTop: "10px",
                        }}
                      />
                      <p className="legend">No Property Photos</p>
                    </div>
                  </Carousel>
                )}
              </div>
              <div className="lead-profile-info">
                <Header as="h3">
                  {/* eslint-disable */}
                  <a href={"tel:" + "#"} title={"application.phoneMobile"}>
                    <i aria-hidden="true" class="phone icon"></i>
                    {application &&
                    application.primaryLocation &&
                    application.primaryLocation.contact &&
                    application.primaryLocation.contact.phone
                      ? application.primaryLocation.contact.phone
                      : "NA"}
                  </a>
                </Header>
                <Header as="h3">
                  <a
                    href={"mailto:" + " application.email"}
                    title={"application.email"}
                  >
                    <i aria-hidden="true" class="mail icon"></i>
                    {application &&
                    application.primaryLocation &&
                    application.primaryLocation.contact &&
                    application.primaryLocation.contact.email
                      ? application.primaryLocation.contact.email
                      : "NA"}
                  </a>
                  {/* eslint-enable */}
                </Header>
                {application && application.coApplicants && (
                  <Header
                    as="h3"
                    icon="location arrow"
                    content={this.getFullAddress(
                      application.primaryLocation.addresses[0]
                    )}
                  />
                )}
                <Header as="h4">
                  Application Created:{" "}
                  {moment(application && application.created).format("lll")}
                </Header>
              </div>
              <div className="lead-profile-bg">
                {this.state.BGdateArray &&
                  this.state.BGdateArray.length > 0 &&
                  this.state.BGdateArray.map((bg) => {
                    return (
                      <>
                        <div className="dashboard-applications-head">
                          <span className="dashboard-application-icon-new"></span>{" "}
                          {bg.node.taApplicationStatus === "Processing"
                            ? "Pending Background Check"
                            : "Background Check"}
                        </div>
                        <div className="dashboard-applications-row">
                          <p className="dashboard-applications-status">
                            {bg.node.taApplicationStatus === "Processing"
                              ? "Pending Background Check"
                              : "Active Background Check!"}
                          </p>
                          <p className="dashboard-applications-address">
                            Submitted: {moment(bg.node.submitted).format("ll")}
                          </p>
                          <p className="go-application-link">
                            {" "}
                            {bg.node.taApplicationStatus === "Processing" ? (
                              <Label color="yellow" horizontal>
                                Pending
                              </Label>
                            ) : (
                              <Label color="green" horizontal>
                                Active
                              </Label>
                            )}
                            {}:{" "}
                            {bg.node.taApplicationStatus !== "Processing" &&
                              moment(bg.node.submitted).diff(
                                moment().subtract(31, "days"),
                                "days"
                              )}{" "}
                            {bg.node.taApplicationStatus !== "Processing" &&
                              "days remaining"}
                          </p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <Divider className="lead-details-divider" />
            {this.state.application &&
              this.state.application.primaryApplicant &&
              this.requirements()}
          </>
        )}
      </>
    );
  }
}

export default Applications;
