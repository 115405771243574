import React ,{useState, useEffect}from 'react';
import { Input, Button, Form, Checkbox, Popup, Modal, Image, Header} from 'semantic-ui-react';
import { Link } from "react-router-dom";
import InsurancePolicyModal from "./InsurancePolicyModal";
import { PAYMENTS_URL,LEASES_URL } from "../../utils/constants";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import mixpanel from "mixpanel-browser";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";
import {
  ConvenienceFees,ConcessionsAmountLease,
  savedInfoPaymentMutationGql,
  personPaymentActionACHGql,
  personPaymentActionCCGql,
} from "../../store/person/payment";
const paymentsClient = getClient(PAYMENTS_URL);
const leaseClient = getClient(LEASES_URL);
const InvoiceDetail = (props) => {
  const [previewTax, setPreviewTax] = useState("CC");
  const [theProps, setProps] = useState(props);
  const [pointsAdded, setPointsAdded] = useState(0);
  const [concessionsAdded, setAddConcessions] = useState(0);
  const [fullAmount, setFullAmount] = useState(true);
  const [newAmount, setNewAmount] = useState(theProps.selectedBill.amountPayable);
  const [concessionsLimit, setConcessionsLimit] = useState(0);
  const [showAmount, setShowAmount] = useState(theProps.selectedBill.amountPayable);
  const [open, setOpen] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [ccInfo, setCCInfo] = useState(theProps.selectedBill.amountPayable);
  const [achInfo, setACHInfo] = useState(theProps.selectedBill.amountPayable);


    const switchCheck = (tog) => {
      if(tog ==true){
        setNewAmount(theProps.selectedBill.amountPayable)
        setAddConcessions(0)
        setFullAmount(true)
      }
      if(tog == false){
        setNewAmount(theProps.selectedBill.amountPayable)
        setAddConcessions(0)
        setFullAmount(false)
      }
    }


    const customLimiter = (e) => {
      if(Number(e) >Number(theProps.selectedBill.amountPayable)){
        return
      }
      if(Math.sign(Number(e)) ==-1){
        return
      }
      if(Number(e) == 0){
        setNewAmount(theProps.selectedBill.amountPayable)
        return
      }
      
      if (Math.sign(parseFloat(parseFloat(achInfo.totalAmount).toFixed(2) - parseFloat(concessionsAdded).toFixed(2)).toFixed(2))== -1) {
        setNewAmount(0)
        return;
      } 
      else {
        setNewAmount(e)
      }
    }

    useEffect(() => {
        getPointsCheck()
      }, [pointsAdded]);

      useEffect(() => {
        getConcessionsCheck()
      }, [concessionsAdded]);

      useEffect(() => {
        getPoints()
      }, [newAmount]);
      useEffect(() => {
        setNewAmount(theProps.selectedBill.amountPayable)
      }, [fullAmount]);
      
      const getConcessionsCheck = () => {
        var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        console.log(Number(concessionsAdded) + Number(newAmount))
        if (Number(concessionsAdded) > concessionsLimit) {
          setAddConcessions(concessionsLimit)
          return;
        } 
        if (Math.sign(parseFloat(parseFloat(achInfo.totalAmount).toFixed(2) - parseFloat(concessionsAdded).toFixed(2)).toFixed(2))== -1) {
          setAddConcessions(0)
          return;
        } 
        if(!regexp.test(concessionsAdded)){
          return;
        }
        if(Number(concessionsAdded) + Number(pointsAdded) / 100 >Number(theProps.selectedBill.amountPayable)){
          setAddConcessions(theProps.selectedBill.amountPayable - Number(pointsAdded)/100)
          return;
        }
        else {
          getPoints()
          const tempDollar = Number(concessionsAdded);
          const testDollar = Number(pointsAdded) / 100;
          const concessionsPoints = Number(testDollar) + Number(concessionsAdded)
          const newAmount = Number(theProps.selectedBill.amountPayable) - Number(concessionsPoints);
          if (newAmount< 0) {
            return;
          } else {
            const finalAmount = newAmount.toFixed(2)
            setAddConcessions(tempDollar)
            setShowAmount(finalAmount)
          }
        }
      }

      useEffect(() => {
        getPoints()
        getConcessions()
      }, []);


      var validate = function(t) {
        return (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;

      }

    const getPointsCheck = () => {
     
      if (+pointsAdded  > theProps.user.pointsBalance) {
        setPointsAdded(0)
        return;
      } 
      if (Number(pointsAdded)/100 > Number(theProps.selectedBill.amountPayable)) {
        setPointsAdded(0)
        return;
      } 
      if(Number(pointsAdded)/100 +concessionsAdded> Number(theProps.selectedBill.amountPayable)){
        setPointsAdded(0)
      }
      else {
        getPoints()
        const tempPoints = pointsAdded == 0 ? 0:Number(pointsAdded) ; 
        const testDollar = Number(tempPoints) / 100;
        const newAmount =
          parseFloat(theProps.selectedBill.amountPayable).toFixed(2) -
          parseFloat(testDollar).toFixed(2);
        if (newAmount < 0) {
          return;
        } 
        else {
        
          const points = pointsAdded ==0 ? 0:+pointsAdded 
          const finalAmount = parseFloat(newAmount).toFixed(2)
              setPointsAdded(points)
              setShowAmount(finalAmount)
        }
      }
    };
      const getConcessions = () => {
        leaseClient
          .query({
            query: ConcessionsAmountLease,
            variables: {
                leaseId: theProps.selectedBill.reference,
              },
          })
          .then((res) => {
            const constant = res.data.concessionsAmountLease.edges[0].node
            setConcessionsLimit(constant.concessionsAmount)
          })
          .catch((e) => {

          });
      };
      const getPoints = () => {
        paymentsClient
          .query({
            query: ConvenienceFees,
            variables: {
                amount: newAmount,
                points: pointsAdded,
                concessionsAmount:concessionsAdded
              },
          })
          .then((res) => {
            const constant = res.data.convenienceFees
            props.setChosenMethod(constant)
            setCCInfo(constant[0])
            setACHInfo(constant[1])
          })
          .catch((e) => {

          });
      };

      const payUsingSaved = () => {
        setLoader(true)
        mixpanel.track("Renter Payment Action", { sub: "General Payment Submit Full Pay" });
        paymentsClient
          .mutate({
            mutation: savedInfoPaymentMutationGql,
            variables: {
              paymentMethod:
            {
                      type: "manual_reoccuring",
                      invoiceId: theProps.selectedBill.Id,
                      useLoyaltyPoints: pointsAdded !==0 ?true:false,
                      loyaltyPoints: pointsAdded,
                      useConcessions: concessionsAdded !== 0 ?true:false,
                      concessionsAmount: concessionsAdded,
                      amount: parseFloat(theProps.selectedBill.amountPayable),
                      reoccuring: {
                        key: theProps.paymentOptions[0].paymentMethodKey.toString(),
                        type:
                          theProps.paymentOptions[0].paymentMethodType === "ACH"
                            ? "bank"
                            : "credit_card",
                      },
                    },
            },
          })
          .then((results) => {
            props.paymentSuccessFail(true);
            setLoader(false)
            toastSuccessMsg("Payment sent!");
            props.getInvoices()
          })
          .catch((e) => {
            setLoader(false)
            toastFailMsg(parseGraphQLErrors(e));
            props.getInvoices()
          });
      };

      const movePage = () => {
        props.setThePoints(pointsAdded)
        props.setTheConcessions(concessionsAdded)
        props.setSelectedBill(theProps.selectedBill)
        props.setInfoStore({"method":previewTax,"amount":newAmount,"full":fullAmount})
        props.setPayInvoice(2)
      }

      const setFullPay = () => {
        props.setThePoints(pointsAdded)
        props.setTheConcessions(concessionsAdded)
        props.setSelectedBill(theProps.selectedBill)
        
        setTimeout(function(){
          props.payUsingSaved();
      }, 5000);
      }





    return(

    <div className="invoice-page-wrap">
        <div className="top-header">
          <Link className="back-arrow" onClick={() => theProps.setPayInvoice(1)}>
            <img src="/assets/img/right-arrow.svg" alt="arrow"></img>
          </Link>
          <h5>Invoice Detail</h5>
        </div>
        <div className="invoice-detail-container">
            <div className="invoice-card-title ">Review Charges</div>
            <div className="invoice-card-subtitle mb-5">{theProps.selectedBill.Location&&theProps.selectedBill.Location.name}</div>
            <div className="card-row">
                <div className="card-col">Primary Charge &nbsp;
                <Button.Group compact size="mini">
                    <Button compact size="mini" positive={fullAmount} onClick={() => switchCheck(true)}>Full</Button>
                    <Button.Or text='or' />
                    <Button compact size="mini" positive={!fullAmount} onClick={() => switchCheck(false)}>Custom</Button>
                </Button.Group>
                </div>
                {
                  fullAmount ?
                  <div className="card-col">$ { parseFloat(newAmount).toFixed(2)}</div>:
                  <div className="points-applied-wrap">
                   <input min="0" type="number" pattern="^?[0-9]\d*(\.\d)?$" value={newAmount} onChange={(e) => customLimiter(e.target.value)}/>
                  </div>
                }
            </div>
            <div className="card-row">
                <div className="card-col">Convenience Fee &nbsp;
                <Button.Group compact size="mini">
                    <Button compact size="mini" positive={previewTax == "CC"} onClick={() => setPreviewTax("CC")}>CC</Button>
                    <Button.Or text='or' />
                    <Button compact size="mini" positive={previewTax == "ACH"} onClick={() => setPreviewTax("ACH")}>ACH</Button>
                </Button.Group>
                </div>
                <div className="card-col">
                {previewTax == "CC"?(ccInfo.chargeType =="fixed"?`$ ${parseFloat(ccInfo.amount).toFixed(2)}`:`${ccInfo.amount}%`):(achInfo.chargeType =="fixed"?`$ ${parseFloat(achInfo.amount).toFixed(2)}`:`${achInfo.amount}%`)}
                </div>
            </div>
            <div className="card-row">
                <div className="card-col">Discount ({pointsAdded} Loyalty Points = ${Number(pointsAdded) / 100})</div>
                <div className="card-col">- ${Number(pointsAdded) / 100}</div>
            </div>
            <div className="card-row">
                <div className="card-col">Concessions </div>
                <div className="card-col">- ${Number(concessionsAdded)}</div>
            </div>
           <div className="card-divider"></div>
            <div className="card-row">
                <div className="card-col fw-sbold">Total Charges</div>
                <div className="card-col fw-sbold">$ 
                {parseFloat(pointsAdded/100 + concessionsAdded).toFixed(2) !== parseFloat(theProps.selectedBill.amount).toFixed(2) &&
                  <>
                    {previewTax == "CC"?parseFloat(ccInfo.convenienceAmount).toFixed(2):parseFloat(achInfo.convenienceAmount).toFixed(2)}
                  </>
                }
                { parseFloat(pointsAdded/100 + concessionsAdded).toFixed(2) == parseFloat(theProps.selectedBill.amount).toFixed(2) &&
                  <>
                  <span style={{textDecoration:'line-through'}}>{previewTax == "CC"?parseFloat(ccInfo.convenienceAmount).toFixed(2):parseFloat(achInfo.convenienceAmount).toFixed(2)}</span>
                  </>
                }
                </div>
            </div>
            {/* <div className="card-row">
                <div className="card-col">Amount Paid</div>
                <div className="card-col">- $5000.00</div>
            </div> */}
            <div className="card-divider"></div>
            <div className="card-row">
                <div className="card-col font-colored">Balance Due </div>
                <div className="card-col font-colored">$ 
                { 
                  parseFloat(pointsAdded/100 + concessionsAdded).toFixed(2) !== parseFloat(theProps.selectedBill.amount).toFixed(2) &&
                  <>
                  {previewTax == "CC"? parseFloat(parseFloat(ccInfo.totalAmount).toFixed(2) - parseFloat(concessionsAdded).toFixed(2)).toFixed(2): parseFloat(Number(achInfo.totalAmount)- Number(concessionsAdded)).toFixed(2)}

                  </>
                }
                {
                  parseFloat(pointsAdded/100 + concessionsAdded).toFixed(2) == parseFloat(theProps.selectedBill.amount).toFixed(2) &&
                  <>
                    0
                  </>
                }
                </div>
            </div>
            <div className="balance-card">
                <div className="balance-card-left">
                    <div className="points-icon">
                        <img src="/assets/img/points-colored-icon.svg" alt="arrow"></img>
                    </div>
                    <div>
                        <div className="balance-title">{pointsAdded} REM Points  (${Number(pointsAdded) / 100})</div>
                        <div className="balance-subtitle">Your REM points balance.</div>
                    </div>
                </div>
                <div className="balance-card-right">
                    <div className="points-applied-wrap">
                    <Popup
                    wide="very"
                    trigger={
                      <Button
                        style={{ marginTop: "5px" }}
                        // color="green"
                        icon="flask"
                        content={`Add ${
                            pointsAdded !== 0
                            ? pointsAdded
                            : "some"
                        } Points`}
                      />
                    }
                    content={
                      <Button.Group>
                        <Button
                          className="points-added-buttons"
                          compact
                          disabled={1 + concessionsAdded > theProps.selectedBill.amountPayable}
                          content="100 Points"
                          onClick={() => setPointsAdded(pointsAdded + 100)}
                        />
                        <Button
                          className="points-added-buttons"
                          compact
                          disabled={5 + concessionsAdded > theProps.selectedBill.amountPayable}
                          content="500 Points"
                          onClick={() => setPointsAdded(pointsAdded +500)}
                        />
                        <Button
                          className="points-added-buttons"
                          compact
                          disabled={10 + concessionsAdded > theProps.selectedBill.amountPayable}
                          content="1000 Points"
                          onClick={() => setPointsAdded(pointsAdded + 1000)}
                        />
                        <Button
                          className="points-added-buttons"
                          compact
                          content="Clear"
                          onClick={() => setPointsAdded(0)}
                        />
                      </Button.Group>
                    }
                    on="click"
                    pinned
                    position="top center"
                  />
                    </div>
                </div>
            </div>
                    {
                      theProps.selectedBill.reference !== null &&
            <div className="balance-card">
                <div className="balance-card-left">
                    <div className="points-icon">
                        <img src="/assets/img/points-colored-icon.svg" alt="arrow"></img>
                    </div>
                    <div>
                        <div className="balance-title">REM Concessions Available (${Number(concessionsLimit).toFixed(2)})</div>
                        <div className="balance-subtitle">Your REM Concessions amount.</div>
                    </div>
                </div>
                <div className="balance-card-right">
                    <div className="points-applied-wrap">
                      <input min="0" type="number" pattern="^?[0-9]\d*(\.\d)?$" value={concessionsAdded} onChange={(e) => setAddConcessions(validate(e.target.value))}/>
                    </div>
                </div>
            </div>
                    }
            {/* <div className="services-card-wrap">
                <div className="services-card-title">Avail Amazing Leasera Services</div>
                <div className="services-card-row">
                    <Checkbox className="services-checkbox" label='' />
                    <div className="services-description">
                        <div className="service-title">Dog walking</div>
                        <div className="service-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat metus metus, vitae maximus orci ultrices id. <span className="view-link">View More</span></div>
                    </div>
                    <div className="service-fee">$15/Month</div>
                </div>
                <div className="services-card-row">
                    <Checkbox className="services-checkbox" label='' />
                    <div className="services-description">
                        <div className="service-title">Points Accelerator</div>
                        <div className="service-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat metus metus, vitae maximus orci ultrices id. <span className="view-link">View More</span> </div>
                    </div>
                    <div className="service-fee">$15/Month</div>
                </div>
                <div className="services-card-row">
                    <Checkbox className="services-checkbox" label='' />
                    <div className="services-description">
                        <div className="service-title">Renter's Insurance</div>
                        <div className="service-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat metus metus, vitae maximus orci ultrices id. <InsurancePolicyModal /> </div>
                    </div>
                    <div className="service-fee">$15/Month</div>
                </div>
            </div> */}
            <div className="button-wrapper">
            {parseFloat(pointsAdded/100 + concessionsAdded).toFixed(2) !== parseFloat(theProps.selectedBill.amount).toFixed(2) &&
                <>

                <button
                    type="button"
                    className="btn btn-primary btn-update-profile"
                    onClick={() => movePage()}
                >
                
                    Proceed to pay ${previewTax == "CC"? parseFloat(parseFloat(ccInfo.totalAmount).toFixed(2) - concessionsAdded).toFixed(2):parseFloat(parseFloat(achInfo.totalAmount).toFixed(2) - concessionsAdded).toFixed(2)}
                </button>
                  </>
              }
                {parseFloat(pointsAdded/100 + concessionsAdded).toFixed(2) == parseFloat(theProps.selectedBill.amount).toFixed(2) &&
                    <>
                    <Modal
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        className="semanticModal"
                        open={open}
                        trigger={
                        <button
                        type="button"
                        className="btn btn-primary btn-update-profile "
                    >
                      Proceed to pay $0.00
                      </button>}
                      >
                        <Modal.Header>Fully Paid!</Modal.Header>
                        <Modal.Content image>
                        <Image size='medium' src='/assets/img/paymentGif.gif' wrapped />
                          <Modal.Description>
                            <p>
                              Due to the addition of either/or Points and Concessions you are fully covered on this bill.
                            </p>
                            <p>The appropriate points will be withdrawn from your account if you have used any. 
                              Any existing used concessions will also be subtracted from your existing balance.</p>
                              <p>If you have any questions about the payment process or the concessions used in this transaction please contact your property manager.</p>
                          </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            
                            loading={loader}
                            disabled={loader}
                            style={{maxHeight:'35.97px'}}
                            onClick={() => payUsingSaved()}
                            positive
                          >
                          Submit
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    
                    </>
                }
            </div>
        </div>
    </div>
    )
}



export default InvoiceDetail;