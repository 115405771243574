import gql from "graphql-tag";

export const createChatRoomGql = gql`
  mutation CreateChatRoom($package: ChatRoomInputType!) {
    createChatRoom(package: $package) {
      response
    }
  }
`;

export const sendTextMessageGql = gql`
  mutation SendtextMessage($package: TextMessageInputType!) {
    sendTextMessage(package: $package) {
      response
    }
  }
`;

export const sendInAppMessageGql = gql`
  mutation SendInAppMessage($package: InAppMessageInputType!) {
    sendInAppMessage(package: $package) {
      response
    }
  }
`;

export const getRenterContactsQuery = gql`
  query getRenterContacts($renterId: String!) {
    getRenterContacts(renterId: $renterId) {
      edges {
        node {
          id
          locationId
          personId
          role
          email
          phone
          name
          picture
          isDelete
        }
      }
    }
  }
`;

export const getChatRoomsQuery = gql`
  query {
    getChatRooms {
      edges {
        node {
          id
          creatorId
          locationId
          createdAt
          updatedAt
          isGroup
          listMemberId
          memberId
          name
          preview
        }
      }
    }
  }
`;

export const getChatRoomLogsQuery = gql`
  query getChatRoomLogsRenter($chatRoomId: String!) {
    getChatRoomLogsRenter(chatRoomId: $chatRoomId, last: 20) {
      edges {
        node {
          id
          chatRoomId
          senderId
          body
          media
          signalWireMetaData
          emailMetaData
          messageLabel
          senderPhone
          receiverPhone
          timeStamp
        }
      }
    }
  }
`;

export const subscribeChatMembersToChatroomGql = gql`
  mutation subscribeChatMembersToChatroom($chat_room_id: Int!) {
    subscribeChatMembersToChatroom(package: { chatRoomId: $chat_room_id }) {
      response
    }
  }
`;

export const getChatRoomDetailsQuery = gql`
  query getChatRoomDetails($chatRoomId: String!) {
    getChatRoomDetails(chatRoomId: $chatRoomId) {
      edges {
        node {
          id
          name
          role
          picture
          personId
        }
      }
    }
  }
`;
