import React, { useState, useEffect } from "react";
import { Button, Modal, Header, Segment, Icon } from "semantic-ui-react";
import { singleLeaseGql, RecipientView,CreateEnv } from '../../store/person/leases';
import { DATE_FORMAT, DOCUSIGN } from '../../utils';
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { PageLoader } from "../../components/Loader/PageLoader";


const LeaseApi = getClient(DOCUSIGN)
export default function ReviewLeaseModal(props) {
  const [open, setOpen] = useState(false);
  const [openURL, setURL] = useState(false);
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    getLease();
  }, []);

  const getLease = () => {
    setOpen(true)
    setLoader(true)
    try {
      LeaseApi
        .mutate({
          mutation:RecipientView,
          variables: {
            input: {
              leaseId: props.match.params.leaseId
            },
          },
        })
        .then((response) => {
          const rec = response.data.recipientView.docusign.url
          setURL(rec)
          setLoader(false)
        })
        .catch((e) => {
          setLoader(false)
          setURL(false)
        });
    } catch (e) {
      setLoader(false)
      setURL(false)
    }
  };



  return (
    <div style={{width:'100%', height:'100%'}}>
        {
          loader ?
            <PageLoader text justify="center" />
            :
            <>
            {
              openURL ?
            <iframe style={{width:'100%', height:'100%'}} src={openURL} ></iframe>
            :
            <Segment placeholder style={{height:'100%'}}>
              <Header icon>
                <Icon name='pdf file outline' />
                No documents are listed for this customer.
              </Header>
            </Segment>
            }
            </>
        }
    </div>

  );
}
