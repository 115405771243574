import React from "react";
import GoogleMapReact from "google-map-react";
import { MAP_DEFAULT_CENTER, DATE_FORMAT } from "../../utils/constants";
import { MapMarker } from "../../components/GoogleMaps/MapMarker";
// import { Carousel } from "../../components/Quill/NewRenterCarousel";
import "./CurrentHomeCard.scss";
import "../../assets/fomantic/dist/semantic.css";
import { Header, Grid, Card, Divider, Image } from "semantic-ui-react";
import Moment from "react-moment";
import { Carousel } from "react-responsive-carousel";
export const CurrentHomeCard = (lease) => {
  console.log(lease)
  const current = lease.primaryTrip.node;
  const hotel = lease.primaryTrip.node.hotelDetails.result&&lease.primaryTrip.node.hotelDetails.result[0];
  // const photos = JSON.parse(current.location.photos);
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid style={{ background: "#f2fcfb" }}>
              <Header as="h1" className="heading heading-md text-center">
              {hotel.hotel_data.name},
              </Header>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="2">
          <Grid.Column width={10}>

                <Carousel showIndicators={false} autoplay={true}>
                  {hotel && hotel.hotel_data.hotel_photos &&
                    hotel.hotel_data.hotel_photos.map((photo) => {
                      return (
                        <>
                          <div className="col-md-12" >
                            <div className="img-tall-overflow">
                              <img className="img-fluid" src={photo.url_original} />
                            </div>
                          </div>
                        </>
                      );
                    })}

                </Carousel>
          </Grid.Column>
          <Grid.Column width={6}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY }}
              defaultCenter={MAP_DEFAULT_CENTER}
              center={{
                lat: hotel.hotel_data.location.latitude,
                lng: hotel.hotel_data.location.longitude,
              }}
              defaultZoom={13}
            >
              <MapMarker
              lat={hotel.hotel_data.location.latitude}
              lng={hotel.hotel_data.location.longitude}
              />
            </GoogleMapReact>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <Grid centered columns={2} relaxed="very">
                  <Grid.Column className="text-center" width={8}>
                    <Header as="h2" style={{ background: "#f2fcfb" }}>
                      Dates
                    </Header>
                    <Divider fitted />
                    <Header>Check In:</Header>
                    <Moment format={DATE_FORMAT}>{current.checkin}</Moment>
                    <Header>Check Out:</Header>
                    <Moment format={DATE_FORMAT}>{current.checkout}</Moment>
                    <Header>Location:</Header>
                    {hotel.hotel_data.address}, {hotel.hotel_data.city}, {hotel.hotel_data.zip}
                  </Grid.Column>
                  <Grid.Column className="text-center" width={8}>
                    <Header as="h2" style={{ background: "#f2fcfb" }}>
                      Info
                    </Header>
                    <Divider fitted />
                    <Header>Price:</Header>
                    ${current.bookingDetails.block.min_price.price}/Night
                    <Header>Reservation:</Header>
                    {current.reservationId}
                    <Header>Link:</Header>
                    <a onclick="window.open(this.href,'_blank');return false;" rel="noopener noreferrer" target="_blank" href={hotel.hotel_data.url}>Website</a>
                  </Grid.Column>
                  <Grid.Column width={16} className="text-center">
                    <Divider horizontal>
                      <Image size="small" src="assets/img/leasera-logonew-nav.png" wrapped />
                    </Divider>
                    <span>
                      <img
                        className="quick-info"
                        src="assets/icons/star-copy.svg"
                        alt="bath icon"
                      />{" "}
                      {hotel.hotel_data.review_score} |{" "}
                    </span>
                    <span>
                      <img
                        className="quick-info"
                        src="assets/icons/R.svg"
                        alt="bedroom icon"
                      />{" "}
                      {current.bookingDetails.block.max_occupancy} |{" "}
                    </span>
                    <span>
                      <img
                        className="quick-info"
                        src="assets/icons/size.svg"
                        alt="size icon"
                      />{" "}
                      {current.bookingDetails.block.room_surface_in_feet2} sq.ft
                    </span>
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default CurrentHomeCard;
