import React, { Component } from "react";
import { personPointsTransactionGql } from "../../store/person/points";
import { QueryOffers } from "../../store/person/person";
import "./Markets.scss";
import { qpTooltipPopover } from "../../utils/misc";
import { POINTS, MARKETING, PAYMENTS_URL } from "../../utils/constants";
import { STATUS, EVENTS, ACTIONS } from "react-joyride";
import "../../assets/fomantic/dist/semantic.css";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";
import {
  personMethodsGql, chargePaymentMethodDonations
} from "../../store/person/payment";

import { Icon, Card, Table, Image, Accordion, Button, Message, Label, Popup, Grid } from "semantic-ui-react";
// import PointsIcon from '../../assets/'
const paymentsClient = getClient(PAYMENTS_URL);
const Points_ledger = getClient(POINTS);
const OffersQuery = getClient(MARKETING);
class Markets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      run: false,
      loading: true,
      ledger: [],
      offers: [],
      amount: false,
      Options: [],
      chosenMethod: false,
      pointsAdded: 0
    };
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  setMethod = (e) => {
    console.log(JSON.parse(e))
    this.setState({ chosenMethod: e })
  }

  handleClickStart = (e) => {
    e.preventDefault();

    this.setState({
      stepIndex: 0,
      run: true,
    });
  };
  getPaymentOptions = () => {
    console.log(":")
    paymentsClient
      .query({
        query: personMethodsGql,
      })
      .then((results) => {
        const options = results.data.paymentMethods.results;
        // this.setState({ chosenMethod: options[0] })
        this.setState({ Options: options });
        // autopayCheck();
      })
      .catch((e) => { });
  };

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === "close"
    ) {
      this.setState({ run: false });
    }
  };

  componentDidMount() {
    qpTooltipPopover();
    this.getPoints();
    this.getOffers();
  }
  getOffers() {
    try {
      OffersQuery.query({
        query: QueryOffers,
      })
        .then((response) => {
          const array = response.data.personServices.edges;
          this.setState({ offers: array, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
    }
  }

  getPoints() {
    this.setState({ loading: true });
    try {
      Points_ledger.query({
        query: personPointsTransactionGql,
      })
        .then((response) => {
          const array = response.data.pointsLedger.edges;
          this.setState({ ledger: array, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });

          // this.setMessage(`Oops! There was a problem fetching points: ${parseGraphQLErrors(error)}`, false)
        });
    } catch (e) {
      console.log(e);
    }
  }

  marketPay = () => {
    this.props.history.push({
      pathname: "/payments/marketPay",
    });
  };

  activateChange = (e) => {
    this.setState({ amount: e })
    this.getPaymentOptions()
  }

  submitPayment = () => {
    const parseMethod = JSON.parse(this.state.chosenMethod)
    try {
      paymentsClient
        .mutate({
          mutation: chargePaymentMethodDonations,
          variables: {
            paymentMethod: {
              type: "manual_reoccuring",
              useLoyaltyPoints: this.state.pointsAdded == 0 ? false : true,
              loyaltyPoints: this.state.pointsAdded,
              amount: this.state.amount,
              reoccuring: {
                key: parseMethod.paymentMethodKey,
                type: parseMethod.paymentMethodType == "Credit Card" ? "credit_card" : "bank"
              }
            }
          },
        })
        .then((res) => {
          toastSuccessMsg("Thank you for your donation!")
          window.location.reload();
        })
        .catch((error) => {
          toastFailMsg("Oh no! Looks like something went wrong.")
          console.log(error);
        });
    } catch (e) {

      console.log(e);
    }
  }

  render() {
    const { offers } = this.state;
    const { activeIndex } = this.state;
    return (
      <>
        <main className="main-content p-2 market-points-wrap" role="main">
          <h3>Points</h3>
          <h6>Here’s the summary of your REM points</h6>
          <div className="row mt-3 points-status">
            <div className="left-data">
              <div className="text-warning">
                <img src="/assets/img/points-icon.svg" alt="points"></img>
              </div>
              <div className="congrats-text">
                <h4>
                  {this.props &&
                    this.props.user &&
                    this.props.user.pointsBalance}{" "}
                  Points
                </h4>
                <span>REM points in your wallet</span>
              </div>
            </div>
            <div className="right-data">
              {/* <button
                className="btn btn-primary"
                onClick={() => this.props.history.push("/markets")}
              >
                redeem Points
              </button> */}
            </div>
          </div>
          <div className="rowScroll activity-status">
            <h2 className="heading heading-md">Points history</h2>
            <Table celled className="fixed_header" stackable>
              <div className="points-table-row">
                <div>
                  <span>Activity</span>
                </div>

                <div>
                  <span>Message</span>
                </div>

                <div>
                  <span>Points</span>
                </div>
              </div>

              <Table.Body style={{ maxHeight: "300px" }}>
                {this.state.ledger.length > 0 &&
                  this.state.ledger.map((Value) => {
                    return (
                      <>
                        <Table.Row>
                          <Table.Cell textAlign="center" width="5">
                            <span className="activity-txt">
                              {Value.node.description}
                            </span>
                            <span className="date-txt">
                              {moment(Value.node.createdAt).format("LL")}
                            </span>
                          </Table.Cell>
                          <Table.Cell width="8">
                            <span className="activity-txt">
                              {Value.node.customMessage}
                            </span>
                          </Table.Cell>
                          <Table.Cell textAlign="center" width="3">

                            {Value.node.pointsUsed > 0 && (
                              <>
                                <i className="arrow down icon"></i>
                                <p className="text-danger">
                                  -{Value.node.pointsUsed}
                                </p>
                              </>
                            )}
                            {Value.node.pointsAdded > 0 && (
                              <>
                                <i className="arrow up icon"></i>
                                <p className="text-success">
                                  {Value.node.pointsAdded}
                                </p>
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
          <br></br>
          <br></br>
          <div>
            <Message
              attached
              header='Giving'
              color="violet"
            />
            <Card.Group itemsPerRow='2' centered>
              <Card >
                <Card.Content>
                  <Image
                    floated='right'
                    size='mini'
                    src='assets/img/giving.png'
                  />
                  <Card.Header>Gift Cards</Card.Header>
                  <Card.Meta>250 Points</Card.Meta>
                  <Card.Description>
                    Put your points towards gift cards! Shop hundreds of stores both in person and online. We are constantly updating our selection so check us out every month!
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Button compact fluid>View Service</Button>
                </Card.Content>
              </Card>
              <Card >
                <Card.Content>
                  <Image
                    floated='right'
                    size='mini'
                    src='assets/img/donations.png'
                  />
                  <Card.Header>Donate Points</Card.Header>
                  <Card.Meta>250 Points</Card.Meta>
                  <Card.Description>
                    Donate towards affordable housing in your local area. By using your points REM will donate on your behalf to a housing initiative. Supplement the cost with points as well.
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Button compact fluid data-toggle="modal" data-target="#givePoints">View Service</Button>
                  <div
                    class="modal"
                    id="givePoints"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="givePoints"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content forgot-modal">
                        <div class="modal-header" style={{ paddingBottom: '10px' }}>
                          <Label ribbon style={{ background: 'linear-gradient(96deg, #3b1c5a, #374db1 163%)', color: 'white' }} className="popup-banner" image>
                            <img src="/assets/img/points-icon.svg" alt="points"></img>
                            250
                            <Label.Detail>Points</Label.Detail>
                          </Label>
                          <h5 class="modal-title" style={{ marginTop: '0px' }} id="forgotPasswordModalLabel">
                            <span class="modal-title">Donate Points </span>
                          </h5>
                          <p class="modal-sutitle">
                            We offer three different packages to start your donation. Select which package you would like to use and on the next step you can supplement with points.
                            You will receive 250 points back with each donation.
                          </p>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <img src="assets/img/close-modal.svg" alt="close" />
                          </button>
                        </div>
                        <div class="modal-body" style={{ paddingBottom: '10px' }}>
                          <Button.Group floated="right">
                            <Button animated='vertical' data-toggle="modal" data-target="#payPoints" onClick={() => this.activateChange(10)}>
                              <Button.Content visible>$10</Button.Content>
                              <Button.Content hidden>
                                <Icon style={{ color: 'red' }} name='heart' /> $10
                              </Button.Content>
                            </Button>
                            <Button animated='vertical' data-toggle="modal" data-target="#payPoints" onClick={() => this.activateChange(20)}>
                              <Button.Content visible>$20</Button.Content>
                              <Button.Content hidden>
                                <Icon style={{ color: 'red' }} name='heart' /> $20
                              </Button.Content>
                            </Button>
                            <Button animated='vertical' data-toggle="modal" data-target="#payPoints" onClick={() => this.activateChange(30)}>
                              <Button.Content visible>$30</Button.Content>
                              <Button.Content hidden>
                                <Icon style={{ color: 'red' }} name='heart' /> $30
                              </Button.Content>
                            </Button>
                          </Button.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal"
                    id="payPoints"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="payPoints"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content forgot-modal">
                        <div class="modal-header" style={{ paddingBottom: '10px' }}>
                          <Label ribbon style={{ background: 'linear-gradient(96deg, #3b1c5a, #374db1 163%)', color: 'white' }} className="popup-banner" image>
                            <img src="/assets/img/points-icon.svg" alt="points"></img>
                            250
                            <Label.Detail>Points</Label.Detail>
                          </Label>
                          <h5 class="modal-title" style={{ marginTop: '0px' }} id="forgotPasswordModalLabel">
                            <span class="modal-title">Donating ${this.state.amount - this.state.pointsAdded / 100} {this.state.pointsAdded == 0 ? "" : ` & ${this.state.pointsAdded} Points`}</span>
                          </h5>
                          <p class="modal-sutitle">

                          </p>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <img src="assets/img/close-modal.svg" alt="close" />
                          </button>
                        </div>
                        <div class="modal-body" style={{ paddingBottom: '10px' }}>
                          <div className="form-group">
                            <label>Payment Method</label>
                            <br></br>
                            <select
                              style={{ border: "none", width: "100%" }}
                              id="method"
                              name="method"
                              onChange={(e) => this.setMethod(e.target.value)}
                            >
                              <option value=''></option>
                              {this.state.Options.map((option) => {
                                return (
                                  <option value={JSON.stringify(option)}>
                                    {option.paymentMethod}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="balance-card">
                            <div className="balance-card-left">

                              <div>
                                <Grid columns="equal">
                                  <Grid.Column stretched textAlign="center">
                                    <div >{this.state.pointsAdded} REM Points  (${Number(this.state.pointsAdded) / 100})</div>
                                  </Grid.Column>
                                  <Grid.Column stretched textAlign="center">
                                    <div >You have {this.props?.user?.pointsBalance} points.</div>
                                  </Grid.Column>
                                </Grid>
                              </div>
                            </div>
                            <div className="balance-card-right">
                              <div className="points-applied-wrap">

                                <Button.Group>
                                  <Button
                                    className="points-added-buttons"
                                    compact
                                    disabled={this.state.pointsAdded > this.props?.user?.pointsBalance || this.state.pointsAdded / 100 + 1 >= this.state.amount}
                                    content="100 Points"
                                    onClick={() => this.setState({ pointsAdded: this.state.pointsAdded + 100 })}
                                  />
                                  <Button
                                    className="points-added-buttons"
                                    compact
                                    disabled={this.state.pointsAdded > this.props?.user?.pointsBalance || this.state.pointsAdded / 100 + 5 >= this.state.amount}
                                    content="500 Points"
                                    onClick={() => this.setState({ pointsAdded: this.state.pointsAdded + 500 })}
                                  />
                                  <Button
                                    className="points-added-buttons"
                                    compact
                                    disabled={this.state.pointsAdded > this.props?.user?.pointsBalance || this.state.pointsAdded / 100 + 10 >= this.state.amount}
                                    content="1000 Points"
                                    onClick={() => this.setState({ pointsAdded: this.state.pointsAdded + 1000 })}
                                  />
                                  <Button
                                    className="points-added-buttons"
                                    compact
                                    content="Clear"
                                    onClick={() => this.setState({ pointsAdded: 0 })} />
                                </Button.Group>

                              </div>
                            </div>
                            <div className="modal-footer" style={{ background: '#fafafa' }}>
                              <Button disabled={!this.state.chosenMethod} compact fluid data-dismiss="modal" onClick={() => this.submitPayment()}>Donate</Button>
                              {/* <button
                                type="button"
                                className="btn"
                                data-dismiss="modal"
                                onClick={() => this.submitPayment()}
                              >
                                Donate{" "}
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </Card.Content>
              </Card>
            </Card.Group>
          </div>
          <br></br>
          <div>
            <Message
              attached
              header='Local Services'
              color="olive"
            />
            <Card.Group itemsPerRow='2' centered>
              <Card >
                <Card.Content>
                  <Image
                    floated='right'
                    size='mini'
                    src='assets/img/travel.png'
                  />
                  <Card.Header>Travel Guide</Card.Header>
                  <Card.Meta>250 Points</Card.Meta>
                  <Card.Description>
                    Explore more of your surrounding by taking a tour.  If your traveling or just want to explore more of your home town we have you covered.  Come see our exclusive guide packages today.                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Button compact fluid>View Service</Button>
                </Card.Content>
              </Card>
              <Card >
                <Card.Content>
                  <Image
                    floated='right'
                    size='mini'
                    src='assets/img/cleaning.png'
                  />
                  <Card.Header>Cleaning Service</Card.Header>
                  <Card.Meta>250 Points</Card.Meta>
                  <Card.Description>
                    No time to manage the home?  Select from our assortment of cleaning services in your local area. We offer both subscription based deals or one off cleaning.                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Button compact fluid>View Service</Button>
                </Card.Content>
              </Card>
            </Card.Group>
          </div>
          <h2 className="heading heading-md">Affiliates</h2>
          <Card.Group
            itemsPerRow="4"
            stackable
            onClick={() =>
              mixpanel.track("Renter Market Action", {
                sub: "Offer Deal Click",
              })
            }
          >
            {offers &&
              offers.map((offer) => {
                return (
                  <Card
                    // fluid
                    onClick={() => window.open(offer.node.link, "_blank")}
                    style={{ background: `${offer.node.colorCode}66` }}
                  >
                    <Card.Content>
                      <Image
                        floated="right"
                        size="mini"
                        src={offer.node.logo}
                      />
                      <Card.Header>{offer.node.title}</Card.Header>
                      <Card.Meta style={{ color: "black" }}>
                        Points: {offer.node.points}
                      </Card.Meta>
                      <Card.Description>{offer.node.desc}</Card.Description>
                    </Card.Content>
                  </Card>
                );
              })}
          </Card.Group>
          <div className="row points-spends-wrap">
            <h2 className="heading heading-md">How to spend your points</h2>
            {/* <img src="/assets/img/renter-market.png" alt="points-img"></img> */}
            <Accordion>
              <div className="accordion-list">
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  How do I earn points?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <p>
                  REM Points are out way of giving back to you as a
                    renter. By doing everyday tasks and simply being a great
                    renter you can earn both passively and actively. These
                    points can be used through any of our partners to save you
                    money and get you some truly amazing deals!
                  </p>
                </Accordion.Content>
              </div>
              <div className="accordion-list">
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  What can I use these points for?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <p>
                    When you’re a renter with us, you don’t have to wait to
                    start benefiting. We immediately begin showing you the
                    points you’ve earned, the services directly available
                    through your renter’s portal and the discounts and added
                    benefits you can take advantage of.
                  </p>
                </Accordion.Content>
              </div>
              <div className="accordion-list">
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  How do I spend points?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p>
                    We’re excited to work with all kinds of property managers
                    that are innovative, understand or wish to unlock the true
                    value of the consumer-renter and know there’s far more they
                    can get out of the business. These clients are usually
                    multi-family but we do see mixed use and single family
                    clients taking advantage of the tools and providing better
                    services to their renter community.
                  </p>
                </Accordion.Content>
              </div>
            </Accordion>
          </div>
        </main>
      </>
    );
  }
}

export default Markets;
