import React from "react";

import "./Walkscore.scss";
export const Walkscore = ({ walkScore, transitScore }) => {
  return (
    <div className="walkscore">
      {walkScore && (
        <div className="walkscore-from-api">
          <a
            className="score-link"
            href="https://www.walkscore.com/how-it-works/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/img/walkscore/api-logo.png"
              alt="What's your Walk Score?"
              width="120"
              height="19"
              border="0"
            />
          </a>
          <strong>
            <a
              className="scoretext down3 score-link"
              href="https://www.walkscore.com/how-it-works/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {walkScore}
            </a>
          </strong>
          <span id="ws_info">
            <a
              href="https://www.redfin.com/how-walk-score-works"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="assets/img/walkscore/api-more-info.gif"
                alt=""
                width="13"
                height="13"
              />
            </a>
          </span>
        </div>
      )}
      {transitScore && (
        <div className="transitscore-from-api">
          <a
            className="score-link"
            href="https://www.redfin.com/how-walk-score-works"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/img/walkscore/transit-score-logo.png"
              alt="What's your Walk Score?"
              width="135"
              height="19"
              border="0"
            />
          </a>
          <strong>
            <a
              className="scoretext down3 score-link"
              href="https://www.redfin.com/how-walk-score-works"
              target="_blank"
              rel="noopener noreferrer"
            >
              {transitScore}
            </a>
          </strong>
          <span id="ws_info">
            <a
              href="https://www.redfin.com/how-walk-score-works"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="assets/img/walkscore/api-more-info.gif"
                alt=""
                width="13"
                height="13"
              />
            </a>
          </span>
        </div>
      )}
    </div>
  );
};
