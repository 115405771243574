import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export default function PaymentsTable({
  calculateLastPage,
  columns,
  data,
  defaultColumn,
  fetchData,
  filterTypes,
  from,
  lastPage,
  pageCount: controlledPageCount,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    previousPage,
    prepareRow,
    nextPage,
    canPreviousPage,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: false, //If this works for you this was the fix
      columns,
      filterTypes,
      data,
      defaultColumn,
      // Set low for testing
      initialState: {
        sortBy: [
          {
            id: from === "Saved" ? "node.preferredPayment" : (from === "Invoices" ? "node.dueDate" : null),
            desc: true,
          },
        ],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // const firstPageRows = rows.slice(0, 10);
  React.useEffect(() => {
    calculateLastPage({ pageSize, controlledPageCount });
  }, [calculateLastPage, pageSize, controlledPageCount]);
  // Render the UI for your table

  // creates iterable array for mapping ui buttons.
  /* eslint-disable */
  const pageLength = Array.apply(
    null,
    Array(!isNaN(lastPage) ? lastPage : 0)
  ).map(() => {});
  /* eslint-enable */

  return (
    <>
      <table className="payments-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`t-header 
                  ${
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }`}
                >
                  {column.render("Header")}
                  <br />
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 ? (
            <td colspan="10" style={{ textAlign: "center" }}>
              No Results Found
            </td>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {page.length !== controlledPageCount && (
        <div className="pagination">
          <span className="pagination-index">
            Page{" "}
            <strong>
              {pageIndex + 1} of {lastPage}
            </strong>{" "}
          </span>
          <button
            className="pagination-nav"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>{" "}
          {pageLength.map((k, i) => {
            return (
              <button className="pagination-nav" onClick={() => gotoPage(i)}>
                {i + 1}
              </button>
            );
          })}
          <button
            className="pagination-nav"
            onClick={() => nextPage()}
            disabled={pageIndex + 1 === lastPage}
          >
            {">"}
          </button>{" "}
        </div>
      )}
    </>
  );
}
