import { MONTHS, US_PHONE_REGEX, VALID_CC_YEARS_FROM_NOW } from "./constants";
import moment from "moment";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";

export const formatDate = (date) => {
  const current_datetime = new Date(date);
  return (
    current_datetime.getDate() +
    " " +
    MONTHS[current_datetime.getMonth()] +
    " " +
    current_datetime.getFullYear()
  );
};

export const formatDateUS = (date) => {
  const current_datetime = new Date(date);
  return (
    MONTHS[current_datetime.getMonth()] +
    " " +
    current_datetime.getDate() +
    ", " +
    current_datetime.getFullYear()
  );
};

export const formatDateTo12Hours = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let meridiem = hours >= 12 ? "PM" : "AM";
  let strHour = hours % 12;
  let strMinute = minutes < 10 ? `0${minutes}` : minutes;
  return `${strHour}:${strMinute} ${meridiem}`;
};

// date must come in as "yyyy-mm-dd"
export const formatDateWithSlashes = (dateString) => {
  dateString = dateString.split("-");
  return dateString[1] + "/" + dateString[2] + "/" + dateString[0];
};

export const amountFormatter = (price) =>
  "$" + price.toLocaleString(navigator.language, { minimumFractionDigits: 0 });

export const daysDifference = (sD, eD) => {
  return Math.floor((Date.parse(eD) - Date.parse(sD)) / 86400000);
};

export const totalPrice = (priceNight, days) => {
  return priceNight * days;
};

export const parseGraphQLErrors = (error) => {
  if (!error) return;
  if (!error.toString().includes("GraphQL error:")) return error;

  return error.toString().replace("GraphQL error:", "");
};

export const getValidCreditCardYears = () => {
  const currentYear = new Date().getFullYear();
  const validYears = [];
  for (let i = currentYear; i <= currentYear + VALID_CC_YEARS_FROM_NOW; i++) {
    validYears.push(i);
  }

  return validYears;
};

//BASIC validation of USA phone numbers only.
//Only checks for 10 digit numbers (zip) + phoneNumber
export const telephoneValidation = (phoneNum) => {
  if (!phoneNum) return false;

  for (let i = 0; i < phoneNum.length; i++) {
    if (phoneNum[i].match(US_PHONE_REGEX)) return false;
  }

  let parsed = telephoneFormatter(phoneNum, true);
  if (parsed.length === 10) {
    return true;
  }

  return false;
};

//If shrink == false: 555-555-5555 else if shrink == true: 5555555555
export const telephoneFormatter = (phoneNum, shrink = false) => {
  if (phoneNum === "" || phoneNum === undefined || phoneNum == null) return;

  let parsed = "";
  for (let i = 0; i < phoneNum.length; i++) {
    if (!isNaN(parseInt(phoneNum[i]))) {
      parsed += phoneNum[i];
    }
  }
  if (shrink) {
    return parsed;
  }

  return (
    "(" +
    parsed.substr(0, 3) +
    ") " +
    parsed.substr(3, 3) +
    "-" +
    parsed.substr(6)
  );
};

// export const customFieldWarning = (id, valid=true, messageText = "") => {
//   const message = `<div class="form-control-feedback text-danger">${messageText}</div>`

//   if(!valid) {
//     $(id).after(message);
//     $(id).addClass('border-danger');
//     $(id).closest('.form-group').addClass('has-danger');
//     $(id).closest('.form-group').find('label').addClass('text-danger');
//   }
//   else {
//     $(id).after(message);
//     $(id).removeClass('border-danger');
//     $(id).closest('.form-group').removeClass('has-danger');
//     $(id).closest('.form-group').find('label').removeClass('text-danger');
//   }
// }

export const checkIfTrue = (value) => {
  if (value === "true") return true;
  else return false;
};

export const multipleColumnsSearch = (inputValue, data) => {
  let filteredData = data.filter((value) => {
    return (
      value.node.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
      value.node.lastName.toLowerCase().includes(inputValue.toLowerCase())
    );
  });
  return filteredData;
};

export const disablePastDt = (current) => {
  const yesterday = moment().subtract(1, "day");
  return current.isAfter(yesterday);
};
export const disableCurrentDt = (current) => {
  const yesterday = moment().add(1, "day");
  return current.isAfter(yesterday);
};
export const disableCurrentMt = (current) => {
  const yesterday = moment().add(1, "month");
  return current.isAfter(yesterday);
};

export const disableFutureDt = (current) => {
  const yesterday = moment().subtract(1, "day");
  return current.isBefore(yesterday);
};

export const ageValidation = (current) => {
  const age = moment().subtract(18, "year");
  return current.isBefore(age);
};

export const toastFailMsg = (msg) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
export const toastSuccessMsg = (msg) =>
  toast.success(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const handlePlacesSelect = (
  address,
  parentKey,
  index,
  addressChange,
  setAddressFields
) => {
  geocodeByAddress(address)
    .then((results) => {
      const AddressResults = results && results[0].address_components;
      setAddressFields(AddressResults);
      addressChange(
        "streetLine1",
        `${AddressResults[0].long_name} ${AddressResults[1].long_name}`,
        index,
        parentKey,
        "address"
      );
      return getLatLng(results[0]);
    })
    .then((latLng) => {
      latLng.address = address;
    })
    .catch((error) => console.error("Error", error));
};

export const formatErrorMessage = (errors) => {
  if (errors && Array.isArray(errors)) {
    return errors.map((item) => ({ field: item.field, message: item.msg }));
  }
  return errors;
};

export const parsePhotos = (photos) => {
  if (typeof photos === "string") {
    try {
      return parsePhotos(JSON.parse(photos));
    } catch (e) {
      return photos;
    }
  }
  return photos;
};
