import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookie, getAuthToken } from "../store/auth/authUtility";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const isAuth = Boolean(getAuthToken());
  const cookie = getCookie();
  let redirectUrl = "/dashboard";
  const searchParams = rest.location.search;
  if (searchParams) {
    redirectUrl =
      new URLSearchParams(searchParams).get("redirectUrl") || redirectUrl;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        (isAuth && cookie.serviceProfessional === "true" && restricted) ||
        (isAuth && cookie.manager === "true" && restricted) ||
        (!isAuth && restricted) ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={redirectUrl} />
        )
      }
    />
  );
};

export default PublicRoute;
