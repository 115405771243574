import React, { Component, useState, useEffect, useContext } from "react";
import {
  Menu,
  Icon,
  Dropdown,
  Label,
  Feed,
  Divider,
  Button,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Link, useLocation } from "react-router-dom";
import { onMessage } from "firebase/messaging";
import "./TopNav.scss";
import { messaging } from "../../containers/Auth/Firebase";
import { logoutAction, getClient } from "../../store/auth";
import { UnloadFCMAttachDevice } from "../../store/person/person";
import moment from "moment";
import { getEmitter, NOTIFICATION } from "../../utils";
import CountUp from "react-countup";
import { NotificationContext } from "../NotificationContext";
import { PERSON_URL, PERSON_IMAGE_URL } from "../../utils/constants";
import { RESET_CHAT } from "../../store/redux/chat";
import { RESET_PERSON } from "../../store/redux/person";
import { useDispatch } from "react-redux";

const eventEmit = getEmitter();

function TopNav(props) {
  const { getAlerts, useUpdateEffect, unreadAlerts } =
    useContext(NotificationContext);
  /* eslint-disable */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* eslint-enable */
  const [newPoints, setNewPoints] = useState(0);
  const [menuToggle, setMenuToggle] = useState(false);
  const dispatch = useDispatch();

  /* eslint-disable */
  useEffect(() => {
    setNewPoints(props.user.pointsBalance);
    getAlerts();
    // eventEmit.on(NOTIFICATION, (data) => this.updateNotif(data));
    messaging &&
      onMessage(messaging, function (payload) {
        console.log("Message received. ", payload);
        const eventEmit = getEmitter();
        eventEmit.emit(NOTIFICATION, payload);
      });
  }, []);
  /* eslint-enable */

  // componentDidUpdate(prevProps) {
  //   // if (this.state.currentPoints !== this.state.newPoints) {
  //   //     setTimeout(() => {
  //   //       this.setPoints();
  //   //     }, 1000);
  //   //   }
  // }

  const handleLogout = () => {
    UnloadFCM();
    logoutAction(props.client).then(() => {
      dispatch({ type: RESET_CHAT });
      dispatch({ type: RESET_PERSON });
      props.setLoading(false);
      setTimeout(() => props.history.push("/"), 1000);
    });
  };
  const UnloadFCM = () => {
    const personMainClient = getClient(PERSON_URL);
    try {
      personMainClient
        .mutate({
          mutation: UnloadFCMAttachDevice,
          variables: {
            input: {
              device: {
                deviceToken: props.currentUser,
              },
            },
          },
        })
        .then((res) => {})
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {useUpdateEffect()}
      <Menu
        fixed="top"
        borderless
        className={props.leftNavCollapsed ? "topnav expanded" : "topnav"}
      >
        <img
          src="/assets/img/Realty-Logos-05.png"
          className="logo-small"
          alt="Leasera"
        />
        {/* <TopNavItemBar icon='bars' onClick={this.props.leftNavToggle} {...this.props} /> */}
        <Menu.Menu position="right" className="header-right-menu">
          <div className="top-nav-item-point">
            <div className="top-nav-item">
              <span className="points-icon"></span>
              <Link to="/markets">
                <CountUp
                  className="points"
                  delay={0}
                  duration={20}
                  start={currentPoints}
                  end={newPoints}
                />
                <span className="top-nav-text">Points</span>
              </Link>
            </div>
          </div>
          <TopNavItem
            history={props.history}
            unreadAlerts={unreadAlerts}
            setMenuToggle={setMenuToggle}
          />
          <TopNavUserMenu
            {...props}
            handleLogout={handleLogout}
            setMenuToggle={setMenuToggle}
          />
          <div
            className="user-menu d-md-none"
            onClick={() => setMenuToggle(menuToggle ? false : true)}
          >
            <div className="top-nav-item">
              <Icon name="bars" className="large-icon" />
            </div>
          </div>
          {/* <Dropdown
              item
              floating
              direction="left"
              trigger={
                <div className="top-nav-item">
                  <Icon name='bars' className="large-icon" />
                </div>
              }
              className="user-menu d-md-none"
            >
            </Dropdown> */}
        </Menu.Menu>
      </Menu>
      {/* Mobile Menu */}
      {menuToggle && (
        <div className="mobile-menu-modal d-md-none">
          <div className="mobile-menu-list">
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/explore" && "active"
              }`}
            >
              <Link to="/explore" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-explore.svg" />
                <span>Explore</span>
              </Link>
            </div>
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/dashboard" && "active"
              }`}
            >
              <Link to="/dashboard" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-leasera.svg" />
                <span>My REM</span>
              </Link>
            </div>
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/schedule" && "active"
              }`}
            >
              <Link to="/schedule" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-schedule.svg" />
                <span>Schedule</span>
              </Link>
            </div>
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/maintenance" && "active"
              }`}
            >
              <Link to="/maintenance" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-maintenance.svg" />
                <span>Maintenance</span>
              </Link>
            </div>
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/markets" && "active"
              }`}
            >
              <Link to="/markets" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-points.svg" />
                <span>Points</span>
              </Link>
            </div>
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/payments" && "active"
              }`}
            >
              <Link to="/payments" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-payments.svg" />
                <span>Payments</span>
              </Link>
            </div>
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/messages" && "active"
              }`}
            >
              <Link to="/messages" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-messages.svg" />
                <span>Messages</span>
              </Link>
            </div>
            <div
              className={`mobile-menu-list-item ${
                props.history.location.pathname == "/profile" && "active"
              }`}
            >
              <Link to="/profile" onClick={() => setMenuToggle(false)}>
                <img src="/assets/img/mobile/icon-profile.svg" />
                <span>Profile</span>
              </Link>
            </div>
            {/* <div className="mobile-menu-list-item">
          <Link to='/dashboard'>
              <img src="/assets/img/mobile/icon-trips.svg" />
              <span>Trips</span>
              </Link>
          </div>
          <div className="mobile-menu-list-item">
          <Link to='/dashboard'>
              <img src="/assets/img/mobile/icon-badges.svg" />
              <span>Badges</span>
              </Link>
          </div> */}
          </div>
          <div className="mobile-menu-button">
            <button className="btn w-100" onClick={() => setMenuToggle(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {/* Mobile Menu */}
    </>
  );
}

export default compose(withRouter, withApollo)(TopNav);

function TopNavItem(props) {
  const openNotificationPage = () => {
    props.setMenuToggle(false);
    props.history.push({
      pathname: "/notifications",
    });
  };

  return (
    <div
      className={`notification-btn ${
        props.unreadAlerts.length !== 0 && "alert"
      }`}
      onClick={openNotificationPage}
    >
      {/* <img src="../../assets/img/icons-notification.svg" alt="icon" /> */}
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd" opacity=".7">
          <path d="M2.52 2.52h15.96v15.96H2.52z" />
          <path
            d="m15.82 14.934.266.354a.332.332 0 0 1-.266.532H5.18a.332.332 0 0 1-.266-.532l.266-.354V9.17a5.32 5.32 0 1 1 10.64 0v5.764zm-6.982 1.551h3.325a1.662 1.662 0 1 1-3.325 0z"
            fill="#5E6267"
            fill-rule="nonzero"
          />
        </g>
      </svg>
    </div>
  );
}

class TopNavUserMenu extends Component {
  render() {
    return (
      <Dropdown
        item
        floating
        direction="left"
        trigger={
          <div className="top-nav-item">
            <Icon
              name="user"
              className="large-icon"
              onClick={() => this.props.setMenuToggle(false)}
            />
          </div>
        }
        className="user-menu"
      >
        <Dropdown.Menu className="profile-list">
          <div className="dropdown-top-menu-user-info">
            <div className="user-image">
              <img
                src={
                  this.props.user.photoPath
                    ? `${PERSON_IMAGE_URL}?path=${this.props.user.photoPath}`
                    : "assets/img/avatar.jpg"
                }
                alt="avatar"
              ></img>
            </div>
            <div className="user-details">
              <p className="user-name mb-0">
                {this.props.user.firstName} {this.props.user.lastName}
              </p>
              <span className="email-id">{this.props.user.email}</span>
              <Dropdown.Item
                className="profile-rw"
                text="View Profile"
                as="a"
                href="/#/profile"
              />
            </div>
          </div>
          {/* <Dropdown.Item text="Loyalty" as="a" href="/#/markets" /> */}
          <Dropdown.Item text="Profile" as="a" href="/#/profile" />
          {/* <Dropdown.Item text="Security" as="a" href="#" /> */}
          {/* <Dropdown.Item text="Billing" as="a" href="/#/payments" /> */}
          {/* <Dropdown.Item text="Maintenance" as="a" href="/#/maintenance" /> */}
          {/* <Dropdown.Item text="Payments" as="a" href="/#/payments" /> */}
          {/* <Dropdown.Item text="Friends" as="a" href="/#/profile" /> */}
          <span className="dropdown-divider-new"></span>
          {/* <Dropdown.Item text="Manage Profile" as="a" href="/#/profile" /> */}
          {/* <Dropdown.Item text="Cars" as="a" href="/#/cars" /> */}
          {/* <Dropdown.Item text="Switch accounts" as="a" href="#" /> */}
          <Dropdown.Item
            text="Sign Out"
            className="signout-txt"
            onClick={this.props.handleLogout}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
