import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb } from 'semantic-ui-react';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import { singleLeaseGql, RecipientView } from '../../store/person/leases';
import { getClient } from "../../store/auth/init-apollo-googleFn";
import "./renewlease.scss";
import { DATE_FORMAT, LEASES_URL,DOCUSIGN } from '../../utils';
import ReviewLeaseModal from '../../components/Modals/ReviewLeaseModal';

const RenewLease = (props) => {
  const LeaseApi = getClient(LEASES_URL)
  const LeaseDocuApi = getClient(DOCUSIGN)
  const [leaseId, setLeaseId] = useState(props.match.params.leaseId);
  const [userInfo, setUserInfo] = useState();
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);

  const fetchLeaseData = async() => {
    try {
      await LeaseApi.query({
        query: singleLeaseGql,
        variables: {
          leaseId,
        },
      }).then((response) => {
        const data = response.data.lease.edges[0].node;
        setData(data);
      });
    } catch (e) {
      console.log(e);
    }
  }

  const getLease = () => {
    // setOpen(true)
    // setLoader(true)
    try {
      LeaseDocuApi
        .mutate({
          mutation:RecipientView,
          variables: {
            input: {
              leaseId: leaseId
            },
          },
        })
        .then((response) => {
          const rec = response.data.recipientView.docusign.url
          window.open(rec, '_blank');
          // props.history.push({
          //   pathname: rec,
          // })
          // setURL(rec)
          // setLoader(false)
        })
        .catch((e) => {
          // setLoader(false)
          // setURL(false)
        });
    } catch (e) {
      // setLoader(false)
      // setURL(false)
    }
  };

  const navigateToRenewLease = (lease) => {
    props.history.push({
      pathname: `/renewlease/docusign/${lease.node.id}`,
    })
  }

  useEffect(() => {
    fetchLeaseData();
  }, []);

  useEffect(() => {
    setUserInfo(props.user)
  }, [props.user])

  const Bread = () => (
    <NavLink exact to="/dashboard">
      <img
        src="/assets/img/right-arrow.svg"
        onClick={() => Bread()}
        alt="arrow"
      />
    </NavLink>
  );

    return (
      <>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="page-header mb-5">
              <div className="d-flex align-items-center">
                <span className="page-back">
                <div className="resident-profile-icon">
                  <span className="back-arrow">
                      <Bread />
                  </span>
                  </div>
                </span>
                <div className="page-header-title pl-0">Review Lease</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="renew-lease-header">
              <div className="card-profile-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="profile-title">
                      {userInfo && (userInfo.firstName + ' ' + userInfo.lastName) }
                    </div>
                    <div className="profile-subtitle">
                      <span>
                        <a href="#">
                          {userInfo && userInfo.email}
                        </a>
                      </span>
                      {' '}
                      <span className="dot-separator"></span>
                      <span>
                      <a href="#">
                        {userInfo && userInfo.phoneMobile}
                      </a>
                      </span>
                    </div>
                    <div className="profile-subtitle">
                      <span>
                        Lease Start Date:
                        {data && moment(data.startDate).format(DATE_FORMAT)}
                      </span>
                      <span className="dot-separator"></span>
                      <span>
                        Lease End Date:
                        {data && moment(data.endDate).format(DATE_FORMAT)}
                      </span>
                      <span className="dot-separator"></span>
                      <span>
                        Total Amount
                        ${data && data.amount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Lease detail row */}
        <div className="row">
          <div className="col-md-12">
            <div className="theme-card card">
              <div className="card-header">
                <div className="card-header-title">Lease Details</div>
              </div>
              <div className="card-body">
                  <div className="card-profile-body">
                    {/* <div className="row">
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Start Date</div>
                          <div className="profile-label-value">
                              {moment(leaseDetail.startDate).format(DATE_FORMAT)}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">
                              End Date
                          </div>
                          <div className="profile-label-value">
                              {moment(leaseDetail.endDate).format(DATE_FORMAT)}
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Unit Type</div>
                          <div className="profile-label-value">
                            {data && data.unit.unitType.type}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Unit Number</div>
                          <div className="profile-label-value">
                            {data && data.unit && data.unit.number}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Frequency</div>
                          <div className="profile-label-value">
                            {data && data.leaseFrequency}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Unit Charge Code</div>
                          <div className="profile-label-value">
                            {data && `Unit - $${data.unitAmount}`}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Unit Amount</div>
                          <div className="profile-label-value">
                            {data && `$${data.unitAmount}`}
                          </div>
                        </div>
                      </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Unit Quantity</div>
                        <div className="profile-label-value">
                            {data && data.unitQuantity}
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Pet Charge Code</div>
                        <div className="profile-label-value">
                          {data && `Pet - $${data.petAmount}`}
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Pet Amount</div>
                        <div className="profile-label-value">
                        ${data && data.petAmount}
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Pet Quantity</div>
                        <div className="profile-label-value">
                            {data && data.petQuantity}
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Vehicle Charge Code</div>
                        <div className="profile-label-value">
                          {data && `Vehicle - $${data.vehicleAmount}`}
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Vehicle Amount</div>
                        <div className="profile-label-value">
                          ${data && data.vehicleAmount}
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Vehicle Quantity</div>
                        <div className="profile-label-value">
                            {data && data.vehicleQuantity}
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Deposit Charge Code</div>
                        <div className="profile-label-value">
                        Deposit
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Deposit Amount</div>
                        <div className="profile-label-value">
                          ${data && data.deposit}
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Non Refundable Amount</div>
                        <div className="profile-label-value">
                        ${data && data.nonRefundable}
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">First and Last Invoice</div>
                        <div className="profile-label-value">
                          {data && data.rules && JSON.parse(data.rules).first_last_invoice ? 'Yes' : 'No'}
                        </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="profile-basic">
                        <div className="profile-label">Guarantee</div>
                        <div className="profile-label-value">
                          {data && data.guarantee ? 'Yes' : 'No'}
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="docs-title">Uploaded Documents</div>
                      <div className="uploaded-docs-row">
                        <div className="uploaded-docs-col">
                          <a href={data && data.extLeaseInfo&&data.extLeaseInfo.document_url} target="_blank" rel="noopener noreferrer">Document</a>
                          {/* <img src="/assets/img/close-modal.svg" alt="close"></img> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Property Manager Row */}
        <div className="row">
          <div className="col-md-12">
            <div className="theme-card card">
              <div className="card-header">
                <div className="card-header-title">Property Manager Information</div>
              </div>
              <div className="card-body py-5">
                <div className="card-row-stats">
                  <div className="stats-col">
                    <div className="stats-label">Property Manager Name</div>
                    <div className="stats-value">{data && (data.propertyManagers[0].firstName + ' ' + data.propertyManagers[0].lastName)}</div>
                  </div>
                  <div className="stats-col">
                    <div className="stats-label">Email</div>
                    <div className="stats-value">{data && data.propertyManagers[0].email}</div>
                  </div>
                  <div className="stats-col">
                    <div className="stats-label">Phone Number</div>
                    <div className="stats-value">{data && data.propertyManagers[0].phoneMobile}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Button row */}
        <div className="row">
        <div className="col-md-12 add-property-container">
          <div className="d-flex justify-content-between">
            {/* <Button className="btn cancel-btn">CaReview Laterncel</Button> */}
            <Button className="btn next-btn" onClick={() =>  getLease()}>Continue to Sign</Button>
            {/* <ReviewLeaseModal leaseId={leaseId}/> */}
            
          </div>
        </div>
      </div>
      </div>
      </>
    )
}

export default RenewLease;