import React from "react";
import { PORTALS } from "../../utils/constants";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import "./PaymentSuccess.scss";
export const PaymentSuccess = (props) => {
  return (
    <Segment placeholder className="success-segment">
      <Header icon>
        {props.sucFail ? (
          <>
            <Icon name="check" />
            <span>Transaction Successful!</span>
            <Header.Subheader>
              <p>
                Thank you for your payment. The transaction has been successfully
                processed and you are all set. Welcome to a new life on leasing!
              </p>
            </Header.Subheader>
          </>
        ) : (
          <>
            <Icon name="exclamation" />
            <span>Transaction Failure!</span>
            <Header.Subheader>
              <p>
                Looks like something went wrong with your payment. Please wait a
                few moments and try again or use a different card. If this
                problem continues please contact support.
              </p>
            </Header.Subheader>
          </>
        )}
      </Header>
      {
        !props.background?
      <Button primary onClick={() => props.setPayInvoice(1)}>
        Back to Invoices
      </Button>:
      <NavLink exact to="/dashboard" className="nav-link">
        <Button primary >
          Back to Dashboard
        </Button>
      </NavLink>
      }
    </Segment>
  );
};
