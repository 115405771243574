import React from "react";
import { Loader } from "./Loader.jsx";
import "./PageLoader.scss";
/**
  Loader was called by previous Quill template, replaced with Semantic Semantic Loader
  Will keep here so we can style our loaders to look the same throughout the app
**/

export function PageLoader({
  wrapperStyle,
  inTable,
  logoStyle,
  stepper,
  text
}) {
  return (
    <div className="container">
      <Loader
        wrapperStyle={wrapperStyle}
        logoStyle={logoStyle}
        inTable={inTable}
        stepper={stepper}
        text={text}
      />
    </div>
  );
}
