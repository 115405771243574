import gql from "graphql-tag";

export const unreadPersonNotificationsGql = gql`
  query UnreadPersonNotifications {
    personNotifications(read: false) {
      edges {
        node {
          ndbId
          message
          created
          read
        }
      }
    }
  }
`;

export const allPersonNotificationsGql = gql`
  query AllPersonNotifications {
    personNotifications {
      edges {
        node {
          ndbId
          message
          created
          read
        }
      }
    }
  }
`;

export const updatePersonNotificationsGql = gql`
  mutation UpdatePersonNotifications($input: UpdatePersonNotificationInput!) {
    updatePersonNotification(input: $input) {
      response
    }
  }
`;
export const ReadNotificationsGql = gql`
  mutation UpdatePersonNotification($input: UpdatePersonNotificationInput!) {
    updatePersonNotification(input: $input) {
      response
    }
  }
`;
