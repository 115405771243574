import React, { Component } from "react";
import { withApollo } from "react-apollo";
import ReeValidate from "ree-validate";
import { createRenterWaitlistGql } from "../../store/person/waitlist";
import {
  DATE_FORMAT,
  WAITLIST_URL,
  UNIT_TYPE_API,
  UNITAPI,
} from "../../utils/constants";
import { qpTooltipPopover } from "../../utils/misc";
import { disablePastDt } from "../../utils/common";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  TextArea,
  Modal,
  Select,
  Grid,
  Header,
} from "semantic-ui-react";
import LeadAlreadyCreated from "./IsUnitAvailableModal";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { unitType, unitInfo } from "../../store/person/properties";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mixpanel from "mixpanel-browser";

const createRenterWaitlist = getClient(WAITLIST_URL);
const unitTypeManager = getClient(UNIT_TYPE_API);
const unitManager = getClient(UNITAPI);

class JoinWaitlist extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate.Validator({
      phoneMobile: "required|numeric|min:10",
      adults: "numeric",
      children: "numeric",
      pets: "numeric",
    });

    this.state = {
      loading: false,
      date: "",
      lead: {
        comment: "",
        phoneMobile: this.props.user.phoneMobile,
        preference: {
          unitType: "",
          unit: this.props.unitId,
          moveInDate: "",
          adults: "",
          children: "",
          pets: "",
          location: "",
          area: {
            city: "",
            state: "",
          },
        },
        location: this.props.propertyCustomId,
      },
      errors: this.validator.errors,
      unitTypeData: [],
      modalOpen: false,
      unitTypeText: "",
      toastOptions: {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        rtl: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      },
      showModal: false,
    };
  }

  componentDidMount() {
    qpTooltipPopover();
    this.fetchUnitTypeData();
    this.fetchUnitData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.phoneMobile !== prevProps.user.phoneMobile) {
      this.setWaitlistPhone();
    }
  }

  setWaitlistPhone = () => {
    const data = this.state.lead;
    this.setState({
      lead: { ...data, phoneMobile: this.props.user.phoneMobile },
    });
  };

  fetchUnitTypeData = async () => {
    const { propertyCustomId } = this.props;
    propertyCustomId &&
      (await unitTypeManager
        .query({
          query: unitType,
          variables: { locationId: propertyCustomId },
        })
        .then((res) => {
          const data = [];
          res.data.unitType &&
            res.data.unitType.edges.forEach((ele, index) => {
              data.push(
                ele && {
                  key: ele.node.type,
                  text: ele.node.type,
                  value: {
                    name: ele.node.type,
                    id: ele.node.id,
                    field: "unitType",
                  },
                }
              );
            });
          this.setState({ unitTypeData: data });
        })
        .catch((error) => {
          toast.error(`Oops! There was a problem : ${error}`);
        }));
  };

  fetchUnitData = async () => {
    const { propertyCustomId, unitId } = this.props;
    propertyCustomId &&
      (await unitManager
        .query({
          query: unitInfo,
          variables: { locationId: propertyCustomId, unitId: unitId },
        })
        .then((res) => {
          const unitData = res.data.unit && res.data.unit.edges[0].node;
          const data = this.state.lead;
          this.setState({
            unitTypeText: unitData.unitType.type,
            lead: {
              ...data,
              preference: {
                ...data.preference,
                unitType: unitData.unitType.id,
              },
            },
          });
        })
        .catch((error) => {
          console.log(error);
        }));
  };

  /* function to remove the error and revalidate the data */
  removeErrorAndReValidate = (name, type) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator
      .validate(
        name,
        type && type === "preference"
          ? this.state.lead[type][name]
          : this.state.lead[name]
      )
      .then(() => {
        this.setState({ errors });
      });
  };

  /* function calls on comment field changed and set the comment value */
  handleCommentChange = (name, e) => {
    this.setState({
      lead: { ...this.state.lead, [name]: e.target.value },
    });
  };

  /* function to accepts the date selected and set it in local state after formatting change */
  handleDateChange = (event) => {
    const data = this.state.lead;
    if (event !== null) {
      const dateRes = moment(event).format(DATE_FORMAT);
      this.setState({
        lead: {
          ...data,
          preference: { ...data.preference, moveInDate: dateRes },
        },
        date: event._d,
      });
    } else {
      this.setState({
        lead: { ...data, preference: { ...data.preference, moveInDate: "" } },
        date: "",
      });
    }
  };

  /* function to set the local state lead data on the basis of the fields data received */
  /* eslint-disable */
  handleChange = (name, e, type) => {
    const { value, textContent } = e.target;
    if (type && type === "preference") {
      const data = this.state.lead;
      {
        name !== "unitType"
          ? this.setState(
              {
                lead: {
                  ...data,
                  preference: { ...data.preference, [name]: value },
                },
              },
              () => {
                this.removeErrorAndReValidate(name, type);
              }
            )
          : this.setState({
              lead: {
                ...data,
                preference: { ...data.preference, [name]: textContent },
              },
            });
      }
    } else {
      this.setState(
        {
          lead: { ...this.state.lead, [name]: value },
        },
        () => {
          this.removeErrorAndReValidate(name);
        }
      );
    }
  };
  /* eslint-enable */

  // Handle new Unit Type
  handleUnitTypeChange = (event, { value }) => {
    const data = this.state.lead;
    this.setState({
      lead: { ...data, preference: { ...data.preference, unitType: value.id } },
      unitTypeText: value.name,
    });
  };

  /* function that validates the data captured, if data is valid, 
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { lead } = this.state;
    const { errors } = this.validator;
    const valid = await this.validator.validateAll(lead);
    if (valid && errors.items.length === 0) {
      this.handleSubmit(lead);
    } else {
      this.setState({ errors });
      toast.error(errors.items[0].msg);
    }
  };

  /* function to delete the empty field from the state */
  removeEmptyData = (field) => {
    const leadData = this.state.lead;
    if (field === "preference") {
      Object.keys(leadData[field]).map((ele) => {
        if (leadData[field][ele] === "" || leadData[field][ele] === {}) {
          delete leadData[field][ele];
        }
        if (leadData[field] === {}) {
          delete leadData[field];
        }
        return null;
      });
    } else if (leadData[field] === "") {
      delete leadData[field];
    }
  };

  /* function to call the CreateLead mutation */
  handleSubmit = async (lead) => {
    this.setState({ loading: true });
    const stateFieldsArr = ["comment", "preference"];
    stateFieldsArr.forEach((ele) => {
      this.removeEmptyData(ele);
    });
    const resLead = { lead };
    mixpanel.track("Renter Application Action", {
      sub: "Sign Up for Waitlist",
    });
    await createRenterWaitlist
      .mutate({
        mutation: createRenterWaitlistGql,
        variables: { input: resLead },
      })
      .then((res) => {
        if (!res.data.renterCreateLead.isLeadExistsWithUnit) {
          toast.success("Renter Waitlist created successfully");
          this.handleClose();
        } else {
          this.setState({ showModal: true });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        toast.error(error, this.state.toastOptions);
        this.setState({ loading: false });
      });
  };

  /* function to set the location and area parameters under preference */
  handleLocationChange = (name, value) => {
    const localLeadData = this.state.lead;
    this.setState({
      lead: {
        ...localLeadData,
        preference: {
          ...localLeadData.preference,
          area: { ...localLeadData.preference.area, [name]: value },
        },
      },
    });
  };

  /* function to close the join waitlist modal */
  handleClose = () => {
    const { errors } = this.validator;
    errors.items = [];
    this.setState({
      modalOpen: false,
      date: "",
      errors,
      showModal: false,
      lead: {
        ...this.state.lead,
        preference: { ...this.state.lead.preference, moveInDate: "", area: {} },
      },
    });
  };

  /* function to render the join waitlist form */
  renderWaitlistForm = (errors, unitTypeText) => (
    <>
      <h4 className="font-weight-bold">
        <span className="property-id">Property Name:</span>{" "}
        {this.props.propertyName}
      </h4>
      <Form ref="form" className="form-submit-leads">
        <Form.Group>
          <Form.Field width={16} inline required>
            <label>First name</label>
            <Input
              label={
                errors.has("firstName") && {
                  tag: true,
                  content: "Required",
                  color: "orange",
                }
              }
              id="firstName"
              name="firstName"
              readOnly
              defaultValue={this.props.user.firstName}
              placeholder="First name"
              className="height-40 cursor-not-allowed"
            />
          </Form.Field>
          <Form.Field width={16} inline required>
            <label>Last name</label>
            <Input
              label={
                errors.has("lastName") && {
                  tag: true,
                  content: "Required",
                  color: "orange",
                }
              }
              id="lastName"
              name="lastName"
              readOnly
              defaultValue={this.props.user.lastName}
              placeholder="Last name"
              className="height-40 cursor-not-allowed"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16} inline required>
            <label>Email</label>
            <Input
              label={
                errors.has("email") && {
                  tag: true,
                  content: "Required",
                  color: "orange",
                }
              }
              id="email"
              name="email"
              readOnly
              defaultValue={this.props.user.email}
              placeholder="Empty"
              className="height-40 cursor-not-allowed"
            />
          </Form.Field>
          <Form.Field width={16} inline required>
            <label>Mobile Phone</label>
            <Input
              label={
                errors.has("phoneMobile") && {
                  tag: true,
                  content: "Required",
                  color: "orange",
                }
              }
              id="phoneMobile"
              name="phoneMobile"
              defaultValue={this.props.user.phoneMobile}
              placeholder="Empty"
              onChange={(event) => {
                this.handleChange("phoneMobile", event);
              }}
              className="height-40"
            />
          </Form.Field>
        </Form.Group>
        <Grid>
          <Grid.Column
            textAlign="left"
            className="add-lead-requirements-preferences"
          >
            <Header as="h3">Housing Requirements & Preferences</Header>
            <Form.Group widths="equal">
              <Form.Field
                width={4}
                control={Select}
                label="Unit Type"
                name="unitType"
                id="unitType"
                selectOnBlur={false}
                selection
                disabled
                search
                text={unitTypeText}
                value={unitTypeText}
                required
                options={this.state.unitTypeData}
                onChange={this.handleUnitTypeChange}
                placeholder="Unit Type"
              />
              <Form.Field
                width={4}
                control={Select}
                label="Unit Number"
                name="unitNumber"
                id="unitNumber"
                selectOnBlur={false}
                disabled
                selection
                search
                required
                text={this.props.unitNumber}
                value={this.props.unitNumber}
                placeholder="Unit Number"
              />
              <Form.Field width={4} inline numeric>
                <label>Adults</label>
                <Input
                  name="adults"
                  id="adults"
                  type="number"
                  placeholder="Adults"
                  onChange={(event) => {
                    this.handleChange("adults", event, "preference");
                  }}
                  className="height-38"
                />
              </Form.Field>
              <Form.Field width={4} inline>
                <label>Children</label>
                <Input
                  name="children"
                  id="children"
                  type="number"
                  placeholder="Children"
                  onChange={(event) => {
                    this.handleChange("children", event, "preference");
                  }}
                  className="height-38"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field width={4}>
                <label>Pets</label>
                <Input
                  name="pets"
                  id="pets"
                  type="number"
                  placeholder="Pets"
                  onChange={(event) => {
                    this.handleChange("pets", event, "preference");
                  }}
                  className="height-38"
                />
              </Form.Field>
              <Form.Field width={4} className="rdtPicker-open-top">
                <label>Move In Date</label>
                <Datetime
                  isValidDate={disablePastDt}
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  selected={this.state.date}
                  onChange={this.handleDateChange}
                  placeholderText="Click to select a date"
                  className="height-38"
                />
              </Form.Field>
              <Form.Field width={4} inline>
                <label>Location</label>
                <Input
                  placeholder="Location"
                  onChange={(e) =>
                    this.handleLocationChange("state", e.target.value)
                  }
                  className="height-38"
                />
              </Form.Field>
              <Form.Field width={4} inline>
                <label>Area</label>
                <Input
                  placeholder="Area"
                  onChange={(e) =>
                    this.handleLocationChange("city", e.target.value)
                  }
                  className="height-38"
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid>
        <Form.Field className="mt-3">
          <label>Comments</label>
          <TextArea
            placeholder="Tell us more"
            onChange={(event) => {
              this.handleCommentChange("comment", event);
            }}
          />
        </Form.Field>
      </Form>
    </>
  );

  render() {
    const { errors, loading, unitTypeText } = this.state;
    return (
      <>
        <Modal
          className="semanticModal add-lead-modal modal-wid-700"
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          trigger={
            <Button disabled={this.props.disabled} className="btn btn-primary">
              Join Waitlist
            </Button>
          }
        >
          <Modal.Header
            textAlign="left"
            className="modal-header-bg position-sticky"
          >
            <Header
              textAlign="left"
              className="modal-heading-custom position-sticky"
            >
              Join Waitlist
            </Header>
          </Modal.Header>
          <>
            <Modal.Content>
              {this.renderWaitlistForm(errors, unitTypeText)}
            </Modal.Content>
            <Modal.Actions>
              <Button
                style={{ background: "#343c49", color: "#F2FCFB" }}
                onClick={() => this.handleClose()}
              >
                Cancel
              </Button>
              <Button
                style={{ background: "#4C158A", color: "#F2FCFB" }}
                content={loading ? "Loading..." : "Save"}
                onClick={(e) => this.validateAndSubmit(e)}
                positive
              />
            </Modal.Actions>
          </>
        </Modal>
        {this.state.showModal && (
          <LeadAlreadyCreated closeModal={this.handleClose} />
        )}
      </>
    );
  }
}

export default withApollo(JoinWaitlist);
