import React from 'react';
import { Modal } from 'semantic-ui-react';
import InsuranceFormModal from "./InsuranceFormModal";

function InsurancePolicyModal() {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      className="insurancePolicyModal"
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<span className="view-link">View More</span>}
    >
      <Modal.Content>
          <div className="modal-logo">
            <img src="/assets/img/logo-color-2.svg" alt="logo"></img>
          </div>
        <Modal.Description>
            <div className="modal-label">Benefits of Renter’s Insurance Policy</div>
            <div className="modal-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat metus metus, vitae maximus orci ultrices id.</div>
            <div className="modal-separator"></div>
            <div className="policy-list">
                <div className="policy-list-item">
                    <span>1</span>
                    <div className="policy-terms">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat metus metus, vitae ma ultrices id.</div>
                </div>
                <div className="policy-list-item">
                    <span>2</span>
                    <div className="policy-terms">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat metus metus, vitae ma ultrices id.</div>
                </div>
                <div className="policy-list-item">
                    <span>3</span>
                    <div className="policy-terms">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat metus metus, vitae ma ultrices id.</div>
                </div>
            </div>
            <div className="policy-price-wrap">
                <div className="pre-price">$300/Month</div>
                <div className="new-price">$200/Month</div>
            </div>
            <InsuranceFormModal />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default InsurancePolicyModal;
