import gql from "graphql-tag";

export const personPointsTransactionGql = gql`
  query pointsLedger {
    pointsLedger {
      edges {
        node {
          id
          status
          description
          pointsUsed
          pointsAdded
          pointsBalance
          pointServiceId
          historicalTotal
          customMessage
          createdAt
        }
      }
    }
  }
`;
