import React, { Component } from "react";
import { parseGraphQLErrors, toastFailMsg } from "../../utils/common";
import { withApollo } from "react-apollo";
import {
  propertiesGql,
  fetchBackgroundInfo,
} from "../../store/person/properties";
import { backgroundCheckGql } from "../../store/person/backgroundCheck";
import {
  allApplicationsGql,
  cancelApplicationGql,
  createApplicationGql,
} from "../../store/person/applications";
import GoogleMapReact from "google-map-react";
import { MapMarker } from "../../components/GoogleMaps/MapMarker";
import "./SinglePropertyDetail.scss";
import {
  MAP_DEFAULT_CENTER,
  COMPLETED,
  DATE_FORMAT,
  FAKE_BG_CHECK,
  INVALID_FORM_INPUT,
  VALID_FORM_INPUT,
  SEARCH_PROP,
  APPLICATIONS_URL,
  BG_CHECK_URL,
  APPLICATION_STATUS,
  LOCATION_UTILS,
} from "../../utils/constants";
import { BasicInfoCard } from "../../components/Property/BasicInfoCard";
import { Carousel } from "../../components/Quill/NewRenterCarousel";
import FlashMessage from "../../components/Messages/FlashMessage";
import { Loader } from "../../components/Loader/Loader";
import Moment from "react-moment";
import BackgroundCheckModal from "../../components/Modals/BackgroundCheckModal";
import { updatePersonGql } from "../../store/person/person";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import "../../assets/fomantic/dist/semantic.css";
import JoinWaitlist from "../../components/Modals/JoinWaitlistModal";
import RequestTour from "../../components/Modals/RequestTourModal";
import mixpanel from "mixpanel-browser";

const newClient = getClient(APPLICATIONS_URL);
const bgClient = getClient(BG_CHECK_URL);
const unitClient = getClient(SEARCH_PROP);
const backgroundClient = getClient(LOCATION_UTILS);
class SinglePropertyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryLocationAmenities: [],
      currentCheck: FAKE_BG_CHECK,
      canApplyHere: true,
      startDate: null,
      flashMessage: "",
      isError: false,
      leaseLength: "one_year",
      bgCheckRequired: null,
      mutationInProgress: false,
      loading: false,
      status: null,
      joinWaitlist: false,
      managers: [],
      backgroundInfo: [],
      properties: [],
    };
  }

  componentWillMount() {
    this.getPropertyDetail();
    // this.getCurrentApplications();
    this.getBgCheck();
  }

  setMessage = (message, isError) => {
    this.setState({
      flashMessage: message,
      isError,
    });
  };

  closeMessage = () => {
    this.setState({ flashMessage: "" });
  };

  setLeaseLength = (event) => {
    const value = event.target.value;
    this.setState({ leaseLength: value });
  };

  setMoveInDate = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    this.setState({ [name]: value });
  };

  addToFavs = () => {
    const { ndbId } = this.state.property;

    if (!ndbId) return this.setMessage("Could not favorite property", true);
    mixpanel.track("Renter Search Action", { sub: "Add Property Favorites" });
    try {
      this.props.oldClient
        .mutate({
          mutation: updatePersonGql,
          variables: { input: { favoriteId: ndbId } },
        })
        .then((response) => {
          this.setMessage("Added to favorites!", false);
        })
        .catch((error) => {
          this.setMessage(
            `Oops! There was a problem: ${parseGraphQLErrors(error)}`,
            true
          );
        });
    } catch (e) {
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  getPropertyDetail = async () => {
    this.setState({ loading: true });
    const { propertyId, unitNumber, bedrooms, bathrooms, sqft } =
      this.props.match.params;
    const Primary = await unitClient.query({
      query: propertiesGql,
      variables: { filter: { id: propertyId } },
    });
    const BackgroundInfo = await backgroundClient.query({
      query: fetchBackgroundInfo,
      variables: { locationId: propertyId },
    });
    this.setState(
      {
        unitNum: unitNumber,
        bedrooms: bedrooms,
        bathrooms: bathrooms,
        sqft: sqft,
        Primary: Primary.data.location && Primary.data.location.edges[0].node,
        backgroundInfo:
          BackgroundInfo.data.locationUtils &&
          BackgroundInfo.data.locationUtils.edges.length &&
          BackgroundInfo.data.locationUtils.edges[0].node
            .applicationInformation,
      },
      () => this.setState({ loading: false })
    );
  };

  getBgCheck = () => {
    try {
      bgClient
        .query({
          query: backgroundCheckGql,
        })
        .then((response) => {
          const currentCheck = response.data.backgroundChecks.edges[0].node;
          this.setState({ currentCheck });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  getCurrentApplications = () => {
    try {
      this.setState({ loading: true });
      newClient
        .query({
          query: allApplicationsGql,
        })
        .then((response) => {
          const apps = response.data.personApplications.edges;
          let canApplyHere = true;
          let applicationId;
          let hasApplied;
          let appDate;
          let status;
          const propertyExists = apps.filter(
            (app) =>
              app.node.property.ndbId === this.props.match.params.propertyId &&
              app.node.status !== APPLICATION_STATUS.canceled
          );
          if (propertyExists.length > 0) {
            applicationId = propertyExists[0].node.ndbId;
            canApplyHere = false;
            hasApplied = true;
            appDate = propertyExists[0].node.created;
            status = propertyExists[0].node.status;
          }

          this.setState({
            canApplyHere,
            applicationId,
            hasApplied,
            appDate,
            status,
            loading: false,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
    }
  };

  applyToProperty = () => {
    const { managers, properties, Unit } = this.state;
    const { propertyId, unitId, unitNumber } = this.props.match.params;
    this.props.history.push({
      pathname: `/applicationForm/${propertyId}/${unitId}/${unitNumber}`,
      Unit,
      managers,
      properties,
      location: propertyId,
      unitId: unitId,
      unitNumber: unitNumber,
    });
    mixpanel.track("Renter Application Action", { sub: "Application Submit" });
  };

  cancelApplication = () => {
    const { applicationId } = this.state;

    this.setState({
      mutationInProgress: true,
    });
    mixpanel.track("Renter Application Action", { sub: "Cancel Application" });
    try {
      newClient
        .mutate({
          mutation: cancelApplicationGql,
          variables: { input: { id: applicationId } },
        })
        .then((response) => {
          this.setMessage("Application canceled", false);
          this.setState({
            canApplyHere: true,
            mutationInProgress: false,
          });
        })
        .catch((error) => {
          this.setMessage(
            `Oops! There was a problem. ${parseGraphQLErrors(
              error
            )}, please try again.`,
            true
          );
          this.setState({
            mutationInProgress: false,
          });
        });
    } catch (e) {
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  render() {
    const {
      Primary,
      Unit,
      canApplyHere,
      hasApplied,
      flashMessage,
      isError,
      appDate,
      bathrooms,
      bedrooms,
      sqft,
      unitNum,
      mutationInProgress,
      currentCheck,
      loading,
      status,
    } = this.state;
    // const address = Primary && Primary.addresses && JSON.parse(Primary.addresses)[0].split(",")
    const photos = Primary && Primary.photos && JSON.parse(Primary.photos);
    const { propertyId, unitId, propertyName, unitNumber } =
      this.props.match.params;
    const tempArr = propertyName.split("_");
    const string = tempArr.join(" ");
    return (
      <>
        {!loading && Primary !== "undefined" && Unit !== "undefined" && (
          <div className="single-property-details-container">
            <div className="single-property-grid">
              <div className="single-property-title">
                <div className="card">
                  <button
                    type="button"
                    className="btn btn-with-icons btn-primary"
                    title="Back"
                    onClick={() => {
                      if (this.props.history.length > 0) {
                        this.props.history.goBack();
                      } else {
                        this.props.history.push("/");
                      }
                    }}
                  >
                    <i className="batch-icon batch-icon-arrow-left"></i>
                  </button>
                  <div className="card-body">
                    <div className="title-address-container">
                      {/* {address && (
                        <h4 className="heading heading-md">
                          {address[0]}
                          , {address[2].charAt(1).toUpperCase() + address[2].slice(2)}
                          , {address[3].toUpperCase()}
                          , {address[4]}
                        </h4>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              {photos && (
                <div className="single-property-carousel">
                  <Carousel photos={photos} carouselId={"mainPageCarousel"} />
                </div>
              )}
              <div className="row">
                <div className="col-md-8">
                  <div className="single-property-info">
                    <div className="basic-info-container">
                      {Primary && (
                        <BasicInfoCard
                          Primary={Primary}
                          unitNum={unitNum}
                          bathrooms={bathrooms}
                          bedrooms={bedrooms}
                          sqft={sqft}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-property-data">
                    {/* <div className="card quick-info">
                      <div className="card-body">
                        <div className="quick-info--container">
                          <span>
                            <img
                              className="quick-info"
                              src="assets/icons/bath.svg"
                              alt="bath icon"
                            />{" "}
                            {Unit && Unit.bathrooms} |{" "}
                          </span>
                          <span>
                            <img
                              className="quick-info"
                              src="assets/icons/bedrooms.svg"
                              alt="bedroom icon"
                            />{" "}
                            {Unit && Unit.bedrooms} |{" "}
                          </span>
                          <span>
                            <img
                              className="quick-info"
                              src="assets/icons/size.svg"
                              alt="size icon"
                            />{" "}
                            {Unit && Unit.sqft.toLocaleString()} sq.ft
                          </span>
                        </div>
                        {Unit && (
                          <div>
                            <h6 className="single-property-points">
                              <em>
                                ${Unit && Unit.price.toLocaleString()}
                                /month
                              </em>
                            </h6>
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div className="single-property-apply">
                      <div className="card">
                        <div className="card-body">
                          {/* <div className="apply--availability">
                            {bgCheckRequired && (
                              <div className="apply--availability--title">
                                Background Check Required
                              </div>
                            )}
                          </div> */}
                          {loading ? (
                            <Loader text />
                          ) : (
                            canApplyHere && (
                              <>
                                {/* <div className="apply--date-selection">
                                  <label htmlFor="">Choose Your Move In Date</label>
                                  <input
                                    className="form-control"
                                    type="date"
                                    onKeyDown={(e) => e.preventDefault()}
                                    required
                                    id="startDate"
                                    onChange={this.setMoveInDate}
                                  />
                                </div>
                                <div className="apply--date-selection">
                                  <label htmlFor="">Select Your Stay Length</label>
                                  <select
                                    className="form-control"
                                    defaultValue={"one_year"}
                                    id="leaseLength"
                                    onChange={this.setLeaseLength}
                                  >
                                    <option value={TARGET_LEASE_LENGTH.six_months}>
                                      6 months
                                    </option>
                                    <option value={TARGET_LEASE_LENGTH.one_year}>
                                      1 year
                                    </option>
                                    <option value={TARGET_LEASE_LENGTH.two_year}>
                                      2 years
                                    </option>
                                    <option value={TARGET_LEASE_LENGTH.three_year}>
                                      3 years
                                    </option>
                                    <option value={TARGET_LEASE_LENGTH.four_year}>
                                      4 years
                                    </option>
                                    <option value={TARGET_LEASE_LENGTH.five_year}>
                                      5 years
                                    </option>
                                  </select>
                                </div> */}
                              </>
                            )
                          )}
                          <div className="apply--cancel">
                            {status && status === "Declined" && (
                              <div className="text-center">
                                We're sorry, but another applicant was selected
                                and this property is no longer available.
                              </div>
                            )}
                            {status &&
                              status === "Declined" &&
                              currentCheck.validFor && (
                                <div className="text-center">
                                  Your background check is still available for{" "}
                                  {currentCheck.validFor} days.
                                </div>
                              )}
                            {status &&
                              status === "Requires Action" &&
                              !canApplyHere &&
                              hasApplied &&
                              !mutationInProgress && (
                                <>
                                  <div className="apply--has-applied">
                                    <div className="apply--has-applied__title">
                                      You've applied to this property
                                    </div>
                                    <div className="apply--has-applied__sub">
                                      <Moment format={DATE_FORMAT}>
                                        {appDate}
                                      </Moment>
                                    </div>
                                  </div>
                                </>
                              )}
                            <div className="apply--button">
                              {canApplyHere && !mutationInProgress && (
                                <>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.applyToProperty()}
                                  >
                                    Apply Now!
                                  </button>
                                </>
                              )}
                              <RequestTour
                                setMessage={this.setMessage}
                                user={
                                  this.props.user ? this.props.user : "Test"
                                }
                                propertyId={
                                  this.props.match &&
                                  this.props.match.params.propertyId
                                }
                                unitId={
                                  this.props.match &&
                                  this.props.match.params.unitId
                                }
                              />
                              <JoinWaitlist
                                user={
                                  this.props.user ? this.props.user : "Test"
                                }
                                propertyCustomId={propertyId}
                                unitId={unitId}
                                unitNumber={unitNumber}
                                propertyName={string}
                              />
                              {mutationInProgress && (
                                <div className="loader-container">
                                  <Loader text justify="center" />
                                </div>
                              )}
                              {status &&
                                status === "Requires Action" &&
                                !canApplyHere &&
                                hasApplied &&
                                !mutationInProgress && (
                                  <button
                                    className="btn btn-warning btn-cancel"
                                    onClick={() => this.cancelApplication()}
                                  >
                                    Cancel Application
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="single-property-rewards">
                    <div className="property-reward-title">Leasera Reward</div>
                    <div className="property-reward-body">
                      <img src="assets/img/reward-icon.svg" alt="reward-icon" />
                      <div className="reward-label">50 Points</div>
                      <div className="reward-detail">You could earn up to 150 extra Leasera points every month just for renting this unit.</div>
                    </div>
                  </div> */}
                  <div className="single-property-map">
                    <div className="map-title">location</div>
                    {Primary && (
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_GMAP_API_KEY,
                        }}
                        defaultCenter={MAP_DEFAULT_CENTER}
                        center={{
                          lat: Primary.location[1],
                          lng: Primary.location[0],
                        }}
                        defaultZoom={15}
                      >
                        <MapMarker
                          lat={Primary.location[1]}
                          lng={Primary.location[0]}
                        />
                      </GoogleMapReact>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="single-property-map">
                {Primary && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GMAP_API_KEY,
                    }}
                    defaultCenter={MAP_DEFAULT_CENTER}
                    center={{
                      lat: Primary.location[1],
                      lng: Primary.location[0],
                    }}
                    defaultZoom={15}
                  >
                    <MapMarker
                      lat={Primary.location[1]}
                      lng={Primary.location[0]}
                    />
                  </GoogleMapReact>
                )}
              </div> */}

              {/* <div className="single-property-info">
                <div className="basic-info-container">
                  <BasicInfoCard
                  />
                </div>
              </div> */}
            </div>
            <BackgroundCheckModal
              addToFavs={this.addToFavs}
              history={this.props.history}
              bgCheck={currentCheck}
            />
            <button
              type="button"
              className="invisible"
              id="bgCheckModalBtn"
              data-toggle="modal"
              data-target="#bgCheckModal"
              aria-label="Close"
            ></button>
          </div>
        )}
        <FlashMessage
          message={flashMessage}
          closeMessage={this.closeMessage}
          isError={isError}
        />
      </>
    );
  }
}

export default withApollo(SinglePropertyDetail);
